import React from "react";
import { BrowserRouter } from "react-router-dom";

import { Routers } from "./routes";
import { AppProvider } from "./utils/Context";

import "./App.css";

const App = () => {
  return (
    <AppProvider>
      <BrowserRouter>
        <Routers />
      </BrowserRouter>
    </AppProvider>
  );
};

export default App;
