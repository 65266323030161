import React, { useState, useContext, useEffect } from "react";
import { ReportingRoutes } from "../../routes";
import Welcome from "../Welcome";
import { AppContext, API, useUtils, Loader } from "../../utils";
import {
  Link,
  useNavigate,
  useLocation,
  useParams,
  Redirect,
} from "react-router-dom";

import dayjs from "dayjs";

const Dashboard = () => {
  const [state, setState] = React.useContext(AppContext);
  let currentPage = state.currentPage;
  
  const [status, setStatus] = useState("loading");
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const utils = useUtils();
  //console.log("params", { params });
  //console.log({ location });
  
  const [reportingData, setReporintgData] = useState();

  useEffect(() => {
    //console.log('state', state );
    if (state.pages && params.pageId && (!state.currentPage || state.currentPage.page.id != params.pageId)) {
      let page = (state.pages || []).find(el => el.page_id == params.pageId );
      if (page) setState((s) => {
        return { ...s, currentPage: page };
      })
      else navigate("/")
    }
    (async () => {
      loadKpis();
    })();
  }, [currentPage , state.reportingPeriod]);

  useEffect(() => { 
    let fn = function (event) {
      let data = event && event.detail && event.detail.data;
      if (data?.type == 'job' && data?.status == 'done') loadKpis();
    };
    window.addEventListener('diggow.msg', fn);
    return function () {
      window.removeEventListener('diggow.msg', fn);
    };
  }, []);

  const loadKpis = async () => {
    if (!currentPage) return;
    try {
      setStatus("loading");
      const res = await API.pages.kpis(currentPage.id, {
        from: dayjs(state.reportingPeriod[0]).format("YYYY-MM-DD"),
        to: dayjs(state.reportingPeriod[1]).format("YYYY-MM-DD"),
      });
      //console.log("res.data", res.data);
      setReporintgData(res.data?.data);
      setState((s) => ({ ...s, currentKpis: res.data?.data }));
      setStatus(res.data?.data?.metrics ? "" : "loading");
    } catch (e) {
      console.log(e);
    }
  }; 
  
  //console.log("reportingData", reportingData);
  
  if (!currentPage) return <Welcome />;

  return (
    <div>
      {status == "loading" ? (
        <>
          <div className="text-center m-5">
            {reportingData?.status == "feeding"
              ? "Récupération en cours..."
              : ""}
          </div>
          <Loader />
        </>
      ) : (
        <div className="tab-contents ">
          {currentPage ? (
            <ReportingRoutes reportingData={reportingData} />
          ) : (
            <Welcome />
          )}
        </div>
      )}
    </div>
  );
};

export default Dashboard;
