import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const VerificationMail = () => {
    const [email, setEmail] = useState('');
    const [verificationCode, setVerificationCode] = useState('');
    const [userCode, setUserCode] = useState('');
    const [isValidEmail, setIsValidEmail] = useState(false);
    const [isCodeCorrect, setIsCodeCorrect] = useState(false);
    const navigate = useNavigate();

    const senderEmail = "souaddjebbi@gmail.com"; // Define the sender email address

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    useEffect(() => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        setIsValidEmail(emailRegex.test(email));
    }, [email]);

    const handleCodeChange = (event) => {
        setUserCode(event.target.value);
    };

    const generateVerificationCode = () => {
        const code = Math.floor(100000 + Math.random() * 900000);
        return code.toString().padStart(6, '0'); // Ensure 6 digits
    };

    const sendVerificationEmail = async () => {
        if (!isValidEmail) {
            alert('Please enter a valid email address.');
            return;
        }

        const code = generateVerificationCode();
        setVerificationCode(code);
        console.log(`Sending verification code ${code} from ${senderEmail} to ${email}`);
        // Simulate email sending
    };

    const handleVerification = () => {
        if (userCode === verificationCode) {
            setIsCodeCorrect(true);
        } else {
            alert('Invalid verification code. Please try again.');
        }
    };

    useEffect(() => {
        if (isCodeCorrect) {
            navigate('/welcome'); // Navigate to welcome page
        }
    }, [isCodeCorrect, navigate]);

    return (
        <div>
            <input
                type="email"
                placeholder="Enter your email"
                value={email}
                onChange={handleEmailChange}
            />
            {isValidEmail ? null : <p style={{ color: 'red' }}>Invalid email format</p>}
            <button onClick={sendVerificationEmail} disabled={!isValidEmail}>
                Send Verification Code
            </button>

            {verificationCode && (
                <div>
                    <p>Enter the verification code sent from {senderEmail} to your email:</p>
                    <input
                        type="text"
                        placeholder="Verification Code"
                        value={userCode}
                        onChange={handleCodeChange}
                    />
                    <button onClick={handleVerification}>Verify</button>
                </div>
            )}
        </div>
    );
};

export default VerificationMail;
