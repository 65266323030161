import React, { useEffect, useState } from "react";

import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import { Button } from "primereact/button";
import { Badge } from "primereact/badge";
import { useFormik } from "formik";
import { Toaster } from "react-hot-toast";
import { useTranslation } from "react-i18next";

import {
  Link,
  useHistory,
  useLocation,
  useParams,
  Redirect,
  useNavigate,
} from "react-router-dom";
import user from "../images/user.svg";
import { Checkbox } from "primereact/checkbox";
import Slice1 from "../images/images/Slice1.svg";
import body_login from "../images/my_images/body_login.png";

import SignUp from "./SignUp";
import { AppContext, API, useUtils, toast } from "../utils";
import SetupAccount from "./SetupAccount";
//import { useNavigate } from "react-router-dom";
const Login = () => {
  const [state, setState] = React.useContext(AppContext);
  const [status, setStatus] = useState("");
  const utils = useUtils();
  const location = useLocation();
  const navigate = useNavigate();

  // Déterminer la langue en fonction de l'URL
  const lang = location.pathname.startsWith("/en") ? "en" : "fr";

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      rm: false,
    },
    validate: (data) => {
      let errors = {};

      if (!data.email) {
        errors.email = "Email requis.";
      } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-_]+\.[A-Z]{2,4}$/i.test(data.email)
      ) {
        errors.email = "Invalide format pour l'email";
      }

      if (!data.password) {
        errors.password = "Mot de passe requis.";
      }

      return errors;
    },
    onSubmit: async (data) => {
      try {
        let { email, password } = data;
        setStatus("saving");
        const res = await API.user.signin({ email, password });
        API.user.setupSession(res.data, state, setState);
        navigate("/");
      } catch (e) {
        toast.error(e.response.data.message);
        setStatus(null);
      }
    },
  });

  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error">{formik.errors[name]}</small>
      )
    );
  };
  const { t, i18n } = useTranslation();
  // useEffect(() => {
  //   if (localStorage.getItem("Lang") == "EN") i18n.changeLanguage("en");
  // }, []);

  return (
    <div>
      <div className="block_login body_gray">
        <form
          onSubmit={formik.handleSubmit}
          className="align"
          autoComplete={!formik.values.rm ? "new-password" : "on"}
        >
          <div className="body_login full_width body_blue h-full">
            <img src={body_login} alt="body_login" className="center_el"></img>
          </div>
          {/* <div class="Vector"></div> */}

          <div className="form_login form_style full_width">
            <div className="logo_flex flex">
              <img src={Slice1} alt="logo_flex" />
              <h1>diggow</h1>
            </div>

            <div className="connect_block">
              <h2>{t("login.login")}</h2>
              <p>{t("login.login_msg")}</p>
            </div>
            <div className="input_login input_style">
              <div className="box_input">
                <label>{t("login.Email_professionnel")}</label>
                <InputText
                  type="text"
                  name="email"
                  defaultValue="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  placeholder="Nom@email.com"
                  //autoComplete={!formik.values.rm ? "new-password" : "on"}
                  className="w-36"
                />
                <small id="username2-help">
                  {getFormErrorMessage("email")}
                </small>
              </div>

              <div className="box_input">
                <label className="Email-professionnel">
                  {t("login.password")}
                </label>
                <InputText
                  name="password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  //autoComplete={!formik.values.rm ? "new-password" : "on"}
                  type="password"
                  defaultValue="email"
                  className="w-36"
                  feedback={false}
                />
                <small
                  id="username2-help"
                  className="text-asap-white font-thin block mx-auto font-Montserrat"
                >
                  {" "}
                  {getFormErrorMessage("password")}
                </small>
              </div>

              <div className=" flex remeber_me_check">
                {/* <div className="checkbox "></div> */}
                <Checkbox
                  inputId="cb1"
                  name="rm"
                  checked={formik.values.rm}
                  onChange={formik.handleChange}
                ></Checkbox>
                <label>{t("login.remember_me")}</label>
              </div>

              <Link to="/reset-password" className="pwd_forget right_el">
                <label> {t("login.lost_password")}</label>
              </Link>

              <button
                type="submit"
                loading={status == "saving"}
                className="btn_url"
              >
                {t("login.login")}
              </button>

              <Toaster
                toastOptions={{
                  className: "",
                  style: {
                    border: "2px solid #713200",
                    padding: "16px",
                    color: "#596ae9",
                    backgroundColor: "#f4f7fb",
                  },
                }}
              />

              <label className="no_compte">
                {" "}
                {t("login.dont_have_account")}
              </label>
              {/* <Link to="/SignUp"> */}

              <Link to="/SignUp">
                <button
                  className="btn_url_cover"
                  //onClick={() => utils.goTo("/Signup")}
                >
                  {t("login.signup")}
                </button>
              </Link>
              {/* </Link> */}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
export default Login;
