import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import fbIcon from "../images/images/fbIcon.png";
import instaIcon from "../images/images/instaIcon.png";
import linkedinIcon from "../images/images/linkedinIcon.png";
import twitterIcon from "../images/images/twitterIcon.png";
import tiktokIcon from "../images/images/tiktokIcon.png";
import deleteIcon from "../images/images/delete.png";
import youtubeIcon from "../images/images/youtubeIcon.svg";
import "./PageManagement.css";

import SocialModals from "./SocialModals";

import { useNavigate } from "react-router-dom";

import "./PagesSetup.css"; //core css

import { API, Loader, toast, AppContext } from "../utils";

import { ConfirmPopup } from "primereact/confirmpopup"; // To use <ConfirmPopup> tag
import { confirmPopup } from "primereact/confirmpopup"; // To use confirmPopup method
// import { useTranslation } from "react-i18next";

let msgListener = null;

const PageManagement = () => {
  const [state, setState] = React.useContext(AppContext);
  //   const { t, i18n } = useTranslation();
  const [socialResult, setSocialResult] = useState(null);
  const [status, setStatus] = useState("loading");
  const [pages, setPages] = useState(state?.pages);
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const loadPages = (s) => {
    setStatus(s || "loading");
    return API.run(async () => {
      let p = await API.pages.all();
      p = p.data?.data && p.data?.data.length ? p.data?.data : null;
      setPages(p);
      setState((os) => ({ ...os, pages: p }));
    }, setStatus);
  };

  const addSocialLink = (provider) => {
    let popup = window.open(
      API.socialAccounts.redirection(provider),
      "Diggow Social Link",
      "width=580,height=700"
    );
    if (msgListener) {
      window.removeEventListener("message", msgListener);
    }
    msgListener = (event) => {
      console.log("window.addEventListener", event.data.pages);
      window.removeEventListener("message", msgListener);
      msgListener = null;
      event.data.social_type = provider;
      event.data.pages = (event.data.pages ?? []).filter(
        (el) => el.social_type == provider
      );
      if (!event.data.pages.length) {
        toast(
          "Aucune liaison trouvée avec ce compte. Vérifiez que vous disposez des droits nécessaires."
        );
        return;
      }
      console.log(event.data);
      setSocialResult(event.data);
      setStatus("selectingPages");
    };
    window.addEventListener("message", msgListener);
    popup.focus();
  };

  const onSavePages = () => {
    loadPages("saving");
  };

  const delPage = (social_account_id, social_id, event) => {
    confirmPopup({
      className: "rounded-2xl",
      rejectClassName: "bg-asap-orange",
      target: event.currentTarget,
      message: "Sûr(e) de vouloir supprimer ?",
      icon: "pi pi-exclamation-triangle",
      acceptLabel: "Oui",
      rejectLabel: "Non",
      accept: () => {
        setStatus("deleting");
        API.run(async () => {
          await API.socialAccounts.storePages(social_account_id, [], {
            [social_id]: true,
          });
          await loadPages("deleting");
        }, setStatus);
        console.log("function");
        console.log("remove =>", social_account_id, social_id);
      },
    });
    console.log("confirms", setStatus);
  };

  const showPages = () => {
    if (pages == null) return;
    let pagesByType = {};
    pages.forEach(
      (el) =>
        (pagesByType[el.social_type] = [
          ...(pagesByType[el.social_type] ?? []),
          el,
        ])
    );

    const confirm = () => {
      navigate("/");
      window.location.reload();
    };

    const editPage = () => {};
    return (
      <div className="bg-gradient-to-r bg-white   from-asap-pGradient shadow-2xl   to-asap-blue container dark:from-diggow-dark-300 dark:to-diggow-dark-300  p-8 pl-12 rounded-3xl  flex flex-col mt-8 md:mt-0 w-5/6 mx-auto mb-5 md:w-max md:mb-0   ">
        <div className="pages-container bg-white">
          <p className="font-inter text-[24px] text-[#152935] font-bold">
            {t("Options.page_management_msg")}
          </p>
          {/* Youtube */}
          {pagesByType["youtube"] && pagesByType["youtube"].length && (
            <>
              <div className="flex flex-row md:ml-4 mt-6 items-center justify-start">
                <img className="w-10  h-10 mr-8" src={youtubeIcon} alt="" />
                <h1 className="text-asap-orange   text-sm  md:text-l font-Montserrat font-bold ">
                  {/* {t("Profils twitter ajoutés")} */}
                  {t("Options.youtube_account")}
                </h1>
              </div>

              <div className="tiktok-container md:overflow-auto md:max-h-60 md:m-4 md:mr-14">
                {pagesByType["youtube"].map((el) => (
                  <div
                    key={el.id}
                    className="flex flex-row gap-2 md:gap-8 mt-6 mr-8 justify-center "
                  >
                    <div className="bg-asap-white flex items-center rounded-xl p-2">
                      <img
                        className=" w-5 h-5 md:w-9 md:h-9 rounded-xl"
                        src={el.page.picture}
                        alt=""
                      />
                    </div>

                    <div className=" flex items-center  md:gap-24 bg-asap-white rounded-xl px-2 md:px-0  md:pr-8  ">
                      <h1 className=" text-asap-blue w-28 font-Montserrat font-bold text-xs md:text-xs  text-left mt-2 md:pl-5">
                        {el.page.name}
                      </h1>
                      <img
                        className="w-5 h-5 mt-1 md:ml-7 cursor-pointer"
                        src={deleteIcon}
                        alt=""
                        onClick={(event) =>
                          delPage(el.social_account_id, el.social_id, event)
                        }
                      />
                      <ConfirmPopup />
                    </div>
                  </div>
                ))}
              </div>
            </>
          )}
          {/* facebbok */}
          {pagesByType["facebook"] && pagesByType["facebook"].length && (
            <>
              <div className="flex flex-row md:ml-4 items-center justify-start mt-5">
                <img className="w-10  h-10 mr-8" src={fbIcon} alt="" />
                <h1 className="text-asap-orange text-sm  md:text-l font-Montserrat font-bold ">
                  {/* {t("Pages Facebook ajoutées")} */}
                  {t("Options.facebook_pages")}
                </h1>
              </div>

              <div className="fbcontainer md:overflow-auto md:max-h-60 md:m-4 md:mr-14 ">
                {pagesByType["facebook"].map((el) => (
                  <div
                    key={el.id}
                    className="flex flex-row gap-2 md:gap-8 mt-6 mr-8 justify-center "
                  >
                    <div className="bg-asap-white flex items-center rounded-xl p-2">
                      <img
                        className=" w-5 h-5 md:w-9 md:h-9 rounded-xl"
                        src={el.page.picture}
                        alt=""
                      />
                    </div>

                    <div className=" flex items-center  md:gap-24 bg-asap-white rounded-xl px-2 md:px-0  md:pr-8 ">
                      <h1 className=" text-asap-blue w-28 font-Montserrat font-bold text-xs md:text-xs  text-left mt-2 md:pl-5">
                        {el.page.name}
                      </h1>
                      <img
                        className="w-5 h-5 mt-1 md:ml-7 cursor-pointer"
                        src={deleteIcon}
                        alt=""
                        onClick={(event) =>
                          delPage(el.social_account_id, el.social_id, event)
                        }
                      />
                      <ConfirmPopup />
                    </div>
                  </div>
                ))}
              </div>
            </>
          )}

          {/* instagram */}
          {pagesByType["instagram"] && pagesByType["instagram"].length && (
            <>
              <div className="flex flex-row mt-8 md:ml-4 items-center justify-start">
                <img className="w-10  h-10 mr-8" src={instaIcon} alt="" />
                <h1 className="text-asap-orange   text-sm  md:text-l font-Montserrat font-bold ">
                  {/* {t("Profils Instagram ajoutés")} */}
                  {t("Options.Instagram_profiles")}
                </h1>
              </div>

              <div className="instacontainer md:overflow-auto md:max-h-60 md:m-4 md:mr-14">
                {pagesByType["instagram"].map((el) => (
                  <div
                    key={el.id}
                    className="flex flex-row gap-2 md:gap-8 mt-6 mr-8 justify-center"
                  >
                    <div className="bg-asap-white flex items-center rounded-xl p-2">
                      <img
                        className="w-5 h-5 md:w-9 md:h-9 rounded-xl"
                        src={el.page.picture}
                        alt=""
                      />
                    </div>
                    <div className=" flex items-center  md:gap-24 bg-asap-white rounded-xl px-2 md:px-0  md:pr-8 ">
                      <h1 className=" text-asap-blue w-28 font-Montserrat font-bold text-xs md:text-xs  text-left mt-2 md:pl-5">
                        {el.page.name}
                      </h1>
                      {/* <i
                        className="w-5 h-5 mt-1 md:ml-7 cursor-pointer pi pi-ellipsis-h"                        
                        onClick={(event) =>
                          editPage(el, event)
                        }
                      /> */}
                      <img
                        className="w-5 h-5 mt-1 md:ml-7 cursor-pointer"
                        src={deleteIcon}
                        alt=""
                        onClick={(event) =>
                          delPage(el.social_account_id, el.social_id, event)
                        }
                      />
                      {/* <Toaster /> */}
                      <ConfirmPopup />
                    </div>
                  </div>
                ))}
              </div>
            </>
          )}

          {/* linkedin */}
          {pagesByType["linkedin"] && pagesByType["linkedin"].length && (
            <>
              <div className="flex flex-row md:ml-4 mt-6 items-center justify-start">
                <img className="w-10  h-10 mr-8" src={linkedinIcon} alt="" />
                <h1 className="text-asap-orange   text-sm  md:text-l font-Montserrat font-bold ">
                  {/* {t("Profils Linkedin ajoutés")} */}
                  {t("Options.linkdin_account")}
                </h1>
              </div>

              <div className="linkedin-container md:overflow-auto md:max-h-60 md:m-4 md:mr-14">
                {pagesByType["linkedin"].map((el) => (
                  <div
                    key={el.id}
                    className="flex flex-row gap-2 md:gap-8 mt-6 mr-8 justify-center "
                  >
                    <div className="bg-asap-white flex items-center rounded-xl p-2">
                      <img
                        className=" w-5 h-5 md:w-9 md:h-9 rounded-xl"
                        src={el.page?.picture}
                        alt=""
                      />
                    </div>

                    <div className=" flex items-center  md:gap-24 bg-asap-white rounded-xl px-2 md:px-0  md:pr-8  ">
                      <h1 className=" text-asap-blue w-28 font-Montserrat font-bold text-xs md:text-xs  text-left mt-2 md:pl-5">
                        {el.page?.name}
                      </h1>
                      <img
                        className="w-5 h-5 mt-1 md:ml-7 cursor-pointer"
                        src={deleteIcon}
                        alt=""
                        onClick={(event) =>
                          delPage(el.social_account_id, el.social_id, event)
                        }
                      />
                      {/* <Toaster /> */}
                      <ConfirmPopup />
                    </div>
                  </div>
                ))}
              </div>
            </>
          )}

          {/* twitter */}
          {pagesByType["twitter"] && pagesByType["twitter"].length && (
            <>
              <div className="flex flex-row md:ml-4 mt-6 items-center justify-start">
                <img className="w-10  h-10 mr-8" src={twitterIcon} alt="" />
                <h1 className="text-asap-orange   text-sm  md:text-l font-Montserrat font-bold ">
                  {/* {t("Profils twitter ajoutés")} */}
                  {t("Options.twitter_account")}
                </h1>
              </div>

              <div className="twitter-container md:overflow-auto md:max-h-60 md:m-4 md:mr-14">
                {pagesByType["twitter"].map((el) => (
                  <div
                    key={el.id}
                    className="flex flex-row gap-2 md:gap-8 mt-6 mr-8 justify-center "
                  >
                    <div className="bg-asap-white flex items-center rounded-xl p-2">
                      <img
                        className=" w-5 h-5 md:w-9 md:h-9 rounded-xl"
                        src={el.page.picture}
                        alt=""
                      />
                    </div>

                    <div className=" flex items-center  md:gap-24 bg-asap-white rounded-xl px-2 md:px-0  md:pr-8  ">
                      <h1 className=" text-asap-blue w-28 font-Montserrat font-bold text-xs md:text-xs  text-left mt-2 md:pl-5">
                        {el.page.name}
                      </h1>
                      <img
                        className="w-5 h-5 mt-1 md:ml-7 cursor-pointer"
                        src={deleteIcon}
                        alt=""
                        onClick={(event) =>
                          delPage(el.social_account_id, el.social_id, event)
                        }
                      />
                      <ConfirmPopup />
                    </div>
                  </div>
                ))}
              </div>
            </>
          )}

          {/* Tiktok */}
          {pagesByType["tiktok"] && pagesByType["tiktok"].length && (
            <>
              <div className="flex flex-row md:ml-4 mt-6 items-center justify-start">
                <img className="w-10  h-10 mr-8" src={tiktokIcon} alt="" />
                <h1 className="text-asap-orange   text-sm  md:text-l font-Montserrat font-bold ">
                  {/* {t("Profils twitter ajoutés")} */}
                  {t("Options.tiktok_account")}
                </h1>
              </div>

              <div className="tiktok-container md:overflow-auto md:max-h-60 md:m-4 md:mr-14">
                {pagesByType["tiktok"].map((el) => (
                  <div
                    key={el.id}
                    className="flex flex-row gap-2 md:gap-8 mt-6 mr-8 justify-center "
                  >
                    <div className="bg-asap-white flex items-center rounded-xl p-2">
                      <img
                        className=" w-5 h-5 md:w-9 md:h-9 rounded-xl"
                        src={el.page.picture}
                        alt=""
                      />
                    </div>

                    <div className=" flex items-center  md:gap-24 bg-asap-white rounded-xl px-2 md:px-0  md:pr-8  ">
                      <h1 className=" text-asap-blue w-28 font-Montserrat font-bold text-xs md:text-xs  text-left mt-2 md:pl-5">
                        {el.page.name}
                      </h1>
                      <img
                        className="w-5 h-5 mt-1 md:ml-7 cursor-pointer"
                        src={deleteIcon}
                        alt=""
                        onClick={(event) =>
                          delPage(el.social_account_id, el.social_id, event)
                        }
                      />
                      <ConfirmPopup />
                    </div>
                  </div>
                ))}
              </div>
            </>
          )}
        </div>

        <div className="justify-center flex">
          <button
            onClick={() => confirm()}
            className="mt-28 md:mt-14 px-14 mx-auto hover:opacity-90  font-Montserrat bg-gradient-to-r text-xs font-medium from-asap-pGradient  mb-5  from-indigo-500 via-purple-500 to-pink-500 py-4 rounded-lg  text-asap-white"
          >
            {/* {t("Retour au tableau de bord")} */}
            {t("Options.back_dashboard")}
          </button>
        </div>
      </div>
    );
  };

  useEffect(() => {
    loadPages();
  }, []);

  if (status == "loading") return <Loader />;
  return (
    <div className="bg-shade ">
      <SocialModals
        onSave={() => onSavePages()}
        socialResult={socialResult}
        visible={status == "selectingPages" && socialResult}
        onHide={() => setStatus("")}
      />

      <section className="  text-white-900  relative mt-28 ">
        <div
          className={` min-h-screen ${
            status ? "opacity-20" : null
          } hero-image bg-right-bottom bg-cover  flex flex-col   `}
        >
          <div className="relative container   mx-auto    md:p-4 flex flex-col justify-center   z-10 bg-[#f7f8fe]">
            {/* //logo  */}

            <div className="flex flex-col xl:flex-row-reverse justify-center items-center gap-8 ">
              {/* first col */}
              <div className="flex flex-col  mx-auto justify-center  px-14  h-fit py-8 rounded-xl shadow-2xl bg-white h-[700px]">
                <div className="mx-auto   ">
                  <h1 className="text-asap-blue dark:text-diggow-blue text-left   md:text-2xl font-Montserrat font-bold mr-3 mb-32">
                    {t("Options.add_social_profile")}
                  </h1>
                </div>
                {/* socials  buttons */}
                <div className="flex mt-14 mx-10 md:mx-4 sm:ml-4 gap-2 md:gap-7 ">
                  <button
                    style={{
                      fontSize: "10px",
                    }}
                    onClick={() => addSocialLink("facebook")}
                    className=" shadow-2xl h-32 opacity-80 hover:opacity-95 text-dblue font-Montserrat bg-shade text-xs font-medium  rounded-3xl px-4  flex  flex-col justify-items-center justify-center items-center"
                  >
                    <img className="w-10 mb-4 h-10" src={fbIcon} alt="" />
                    {t("Options.page_facebook")}
                  </button>

                  <button
                    style={{
                      fontSize: "10px",
                    }}
                    onClick={() => addSocialLink("instagram")}
                    className=" shadow-2xl h-32 opacity-80 hover:opacity-95 text-dblue font-Montserrat bg-shade text-xs font-medium      rounded-3xl px-4 flex  flex-col justify-items-center justify-center items-center"
                  >
                    <img className="w-10 mb-4 h-10" src={instaIcon} alt="" />
                    {t("Options.Compte_Instagram")}
                  </button>

                  <button
                    style={{
                      fontSize: "10px",
                    }}
                    onClick={() => addSocialLink("linkedin")}
                    className="shadow-2xl h-32 opacity-80 hover:opacity-95 text-dblue font-Montserrat bg-shade text-xs font-medium      rounded-3xl px-4 flex  flex-col justify-items-center justify-center items-center"
                  >
                    <img className="w-10 mb-4 h-10" src={linkedinIcon} alt="" />
                    {t("Options.Profil_linkedin")}
                    {/* (Coming soon!) */}
                  </button>

                  <button
                    style={{
                      fontSize: "10px",
                    }}
                    onClick={() => addSocialLink("twitter")}
                    //className="shadow-2xl h-32 opacity-80 hover:opacity-95 text-asap-white font-Montserrat bg-gradient-to-r text-xs font-medium from-asap-pGradient    to-diggow-blue rounded-3xl px-4 flex  flex-col justify-items-center justify-center items-center"
                    className="shadow-2xl h-32 opacity-80 hover:opacity-95 text-dblue font-Montserrat bg-shade text-xs font-medium rounded-3xl px-4 flex  flex-col justify-items-center justify-center items-center"
                  >
                    <img className="w-10 mb-4 h-10" src={twitterIcon} alt="" />
                    {t("Options.Profile_twitter")}
                    {/* (Coming soon!) */}
                  </button>

                  <button
                    style={{
                      fontSize: "10px",
                    }}
                    //onClick={() => addSocialLink("tiktok")}
                    className="shadow-2xl h-32 opacity-80 hover:opacity-95 text-dblue font-Montserrat bg-shade text-xs font-medium rounded-3xl px-4 flex  flex-col justify-items-center justify-center items-center"
                  >
                    <img className="w-10 mb-4 h-10" src={tiktokIcon} alt="" />
                    {/* {t("Options.Compte_tiktok")} */}
                    Coming soon!
                  </button>

                  <button
                    style={{
                      fontSize: "10px",
                    }}
                    onClick={() => addSocialLink("youtube")}
                    className="shadow-2xl h-32 opacity-80 hover:opacity-95 text-dblue font-Montserrat bg-shade text-xs font-medium rounded-3xl px-4 flex  flex-col justify-items-center justify-center items-center"
                  >
                    <img className="w-10 mb-4 h-10" src={youtubeIcon} alt="" />
                    {t("Options.Compte_youtube")}
                    {/* (Coming soon!) */}
                  </button>
                </div>
                {/*  info */}
                {/* vous etes */}
                {/* <div className="flex mt-16 ml-6 items-center">
                  <img
                    className="text-light max-h-6 mt-1 "
                    src={infoLogo}
                    alt=""
                  />
                  <p className="text-asap-text text-xs ml-4 font-Montserrat font-light"> /*}
                    {/* {t("Vous êtes en version d'essai ?")} */}
                {/* Vous êtes en version d'essai ? */}
                <br />
                {/* {t("Vous pouvez ajouter jusqu'à 1 pages Facebook")} */}{" "}
                {/* Vous pouvez ajouter jusqu'à 1 pages Facebook */}
                <br />
                {/* {t("ou profil Instagram ou Linkedin.")} */}
                {/* ou profil Instagram ou Linkedin. */}
                {/* </p>
                </div>  */}
                {/*}  <div className="flex items-center mt-20 gap-2.5	  ">
                  <img src={Pluscircle} className="Pluscircle w-4 h-4 mb-5" />
                  <label className="text-left pr-24 text-[#5a6872]">
                    Vous êtes en version d'essai ? Vous pouvez ajouter jusqu'à 1
                    <br /> pages Facebook ou profil Instagram ou Linkedin.
                  </label>
                </div>*/}
              </div>

              {/* pages container */}

              {showPages()}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
export default PageManagement;
