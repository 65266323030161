import React, { useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { Layout as DataLayout } from "../DataCmp/Layout";
import BenchmarkHeader from "./BenchmarkHeader";
import { AppContext, API, useUtils } from "../../utils";

const Benchmark = (props) => {

  const { t, i18n } = useTranslation();
  const utils = useUtils();

  const [state, setState] = useContext(AppContext);
  const loaded = state && state.userData && state.token;
  console.log('loaded', loaded);  
  useEffect(() => {
    if (!loaded) API.init(state, setState, utils);
  }, []);

  return (
    <>
      <BenchmarkHeader />
      <div className="btn_url_cover cat_name table my-4">
        Category :{" "}
        <span className="font-Montserrat">
          {props.reportingData.category?.name}
        </span>
      </div>
      <DataLayout
        layout={props?.reportingData?.layouts?.bench}
        data={props?.reportingData}
      />
    </>
  );
};

export default Benchmark;
