import React from "react";
import { useTranslation } from "react-i18next";
import Highcharts from "highcharts";
import html2canvas from "html2canvas";
import HighchartsReact from "highcharts-react-official";

import { ReactComponent as UpIcon } from "../../../images/upIcon.svg";
import { ReactComponent as DownIcon } from "../../../images/downIcon.svg";

const EngagementYoutube = (props) => {
  const { t } = useTranslation();
  const printChart = (event) => {
    event.preventDefault();

    let target = event.target;
    let parent = target.parentElement.parentElement;
    let name = parent.getAttribute("data-value");
    parent.classList.add("capture_style");

    html2canvas(parent, {
      onclone: (document) => {},
    }).then((canvas) => {
      const data = canvas.toDataURL("image/jpg");
      const link = document.createElement("a");

      if (typeof link.download === "string") {
        link.href = data;
        link.download = name + ".jpg";

        parent.classList.remove("capture_style");

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        window.open(data);
      }
    });
  };

  const COLORS = ["#596ae9", "#6955e6", "#22c4ac", "red", "orange"];

  let options = {
    credits: false,
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: "pie",
      width: 600,
      style: {
        fontFamily: "Inter",
      },
    },
    colors: COLORS,
    title: {
      text: "",
    },
    tooltip: {
      pointFormat: "<b>{point.percentage:.2f}%</b><br/>{point.y}",
    },
    accessibility: {
      point: {
        valueSuffix: "%",
      },
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: true,
          format: "<b>{point.name}</b>: {point.percentage:.2f} %",
        },
        size: "70%",
      },
    },
    series: [
      {
        innerSize: "50%",
        colorByPoint: true,
        animation: {
          duration: 2000,
        },
        data: [],
      },
    ],
  };

  const my_date = props.state.data.youtube;
  const total_insights = my_date?.insights?.total?.engagment;

  options.series[0].data = [];
  Object.keys(total_insights.interactions).forEach((interaction, index) => {
    options.series[0].data.push({
      name: interaction,
      y: parseInt(total_insights.numbers[interaction]),
    });
  });

  return (
    <div>
      <div className="style_title_chart bg-white">
        <div className="flex items-center">
          <div className="content_style_title">
            <h1>{t("Performance_report.engagement")}</h1>
            <p>{t("Performance_report.engagement_desc")}</p>
          </div>

          <button
            data-html2canvas-ignore
            onClick={printChart}
            className="mx-5 w-5 h-5 print_capture ml-auto"
          ></button>
        </div>

        <div className="highchart_block flex">
          <div data-value="Engagement" className="highchart_block1 full_width">
            <HighchartsReact highcharts={Highcharts} options={options} />
            <div className="capture_style"></div>
          </div>

          <div className="highchart_block1 full_width">
            <div className="bg-rectangle rounded-[8px] mb-10 relative p-5">
              <div className="flex items-center">
                <div className="w-5/5">
                  <span className="text-[#152935] text-[14px] font-['inter'] font-bold ">
                    {t("Performance_report.total_engagement")}
                  </span>
                </div>
              </div>

              <div className="flex items-center">
                <span className="text-[#152935] text-[24px] font-['inter'] font-extrabold mt-5">
                  {total_insights?.total_engagements}
                </span>
              </div>
            </div>

            <div className="text-xs">
              <div className="flex items-center">
                <span className=""></span>
                <div className="ml-2 flex capitalize">Réactions :</div>
                <span className="title_1 flex items-center ml-auto">
                  {total_insights?.numbers?.Réactions?.toLocaleString() ?? 0}
                  {total_insights?.previous?.Réactions > 0 ? (
                    <div
                      style={{
                        width: 15,
                        marginLeft: 15,
                        marginRight: 5,
                        fill: "green",
                      }}
                    >
                      <UpIcon />
                    </div>
                  ) : (
                    <div
                      style={{
                        width: 15,
                        marginLeft: 15,
                        marginRight: 5,
                        fill: "red",
                      }}
                    >
                      <DownIcon />
                    </div>
                  )}
                  {total_insights?.previous?.Réactions.toFixed(2) || 0}%
                </span>
              </div>
              <hr className="mb-4 mt-1" />
            </div>

            <div className="text-xs">
              <div className="flex items-center">
                <span className=""></span>
                <div className="ml-2 flex capitalize">Partages :</div>
                <span className="title_1 flex items-center ml-auto">
                  {total_insights?.numbers?.Partages?.toLocaleString() ?? 0}
                  {total_insights?.previous?.Partages > 0 ? (
                    <div
                      style={{
                        width: 15,
                        marginLeft: 15,
                        marginRight: 5,
                        fill: "green",
                      }}
                    >
                      <UpIcon />
                    </div>
                  ) : (
                    <div
                      style={{
                        width: 15,
                        marginLeft: 15,
                        marginRight: 5,
                        fill: "red",
                      }}
                    >
                      <DownIcon />
                    </div>
                  )}
                  {total_insights?.previous?.Partages.toFixed(2) || 0}%
                </span>
              </div>
              <hr className="mb-4 mt-1" />
            </div>

            <div className="text-xs">
              <div className="flex items-center">
                <span className=""></span>
                <div className="ml-2 flex capitalize">Vues : </div>
                <span className="title_1 flex items-center ml-auto">
                  {total_insights?.numbers?.Vues?.toLocaleString() ?? 0}
                  {total_insights?.previous?.Vues > 0 ? (
                    <div
                      style={{
                        width: 15,
                        marginLeft: 15,
                        marginRight: 5,
                        fill: "green",
                      }}
                    >
                      <UpIcon />
                    </div>
                  ) : (
                    <div
                      style={{
                        width: 15,
                        marginLeft: 15,
                        marginRight: 5,
                        fill: "red",
                      }}
                    >
                      <DownIcon />
                    </div>
                  )}
                  {total_insights?.previous?.Vues.toFixed(2) || 0}%
                </span>
              </div>
              <hr className="mb-4 mt-1" />
            </div>

            <div className="text-xs">
              <div className="flex items-center">
                <span className=""></span>
                <div className="ml-2 flex capitalize">Commentaires : </div>
                <span className="title_1 flex items-center ml-auto">
                  {total_insights?.numbers?.Commentaires?.toLocaleString() ?? 0}
                  {total_insights?.previous?.Commentaires > 0 ? (
                    <div
                      style={{
                        width: 15,
                        marginLeft: 15,
                        marginRight: 5,
                        fill: "green",
                      }}
                    >
                      <UpIcon />
                    </div>
                  ) : (
                    <div
                      style={{
                        width: 15,
                        marginLeft: 15,
                        marginRight: 5,
                        fill: "red",
                      }}
                    >
                      <DownIcon />
                    </div>
                  )}
                  {total_insights?.previous?.Commentaires.toFixed(2) || 0}%
                </span>
              </div>
              <hr className="mb-4 mt-1" />
            </div>

            <div className="text-xs">
              <div className="flex items-center">
                <span className=""></span>
                <div className="ml-2 flex capitalize">Favoris :</div>
                <span className="title_1 flex items-center ml-auto">
                  {total_insights?.numbers?.Favoris?.toLocaleString() ?? 0}
                  {total_insights?.previous?.Favoris > 0 ? (
                    <div
                      style={{
                        width: 15,
                        marginLeft: 15,
                        marginRight: 5,
                        fill: "green",
                      }}
                    >
                      <UpIcon />
                    </div>
                  ) : (
                    <div
                      style={{
                        width: 15,
                        marginLeft: 15,
                        marginRight: 5,
                        fill: "red",
                      }}
                    >
                      <DownIcon />
                    </div>
                  )}
                  {total_insights?.previous?.Favoris.toFixed(2) || 0}%
                </span>
              </div>
              <hr className="mb-4 mt-1" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EngagementYoutube;
