const countries = [
    { name: 'Afghanistan', code: "AF" },
    { name: 'Albanie', code: "AL" },
    { name: 'Algérie', code: "DZ" },
    { name: 'Allemagne', code: "DE" },
    { name: 'Andorre', code: "AD" },
    { name: 'Angola', code: "AO" },
    { name: 'Antigua-et-Barbuda', code: "AG" },
    { name: 'Arabie Saoudite', code: "SA", currency: "$" },
    { name: 'Argentine', code: "AR" },
    { name: 'Arménie', code: "AM" },
    { name: 'Australie', code: "AU" },
    { name: 'Autriche', code: "AT" },
    { name: 'Azerbaïdjan', code: "AZ" },
    { name: 'Bahamas', code: "BS" },
    { name: 'Bahreïn', code: "BH" },
    { name: 'Bangladesh', code: "BD" },
    { name: 'Barbade', code: "BB" },
    { name: 'Belgique', code: "BE" },
    { name: 'Belize', code: "BZ" },
    { name: 'Bénin', code: "BJ" },
    { name: 'Bhoutan', code: "BT" },
    { name: 'Biélorussie', code: "BY" },
    { name: 'Birmanie', code: "MM" },
    { name: 'Bolivie', code: "BO" },
    { name: 'Bosnie-Herzégovine', code: "BA" },
    { name: 'Botswana', code: "BW" },
    { name: 'Brésil', code: "BR" },
    { name: 'Brunei', code: "BN" },
    { name: 'Bulgarie', code: "BG" },
    { name: 'Burkina Faso', code: "BF" },
    { name: 'Burundi', code: "BI" },
    { name: 'Cambodge', code: "KH" },
    { name: 'Cameroun', code: "CM" },
    { name: 'Canada', code: "CA" },
    { name: 'Cap-Vert', code: "CV" },
    { name: 'République centrafricaine', code: "CF" },
    { name: 'Tchad', code: "TD" },
    { name: 'Chili', code: "CL" },
    { name: 'Chine', code: "CN" },
    { name: 'Chypre', code: "CY" },
    { name: 'Colombie', code: "CO" },
    { name: 'Comores', code: "KM" },
    { name: 'République du Congo', code: "CG" },
    { name: 'République démocratique du Congo', code: "CD" },
    { name: 'Corée du Nord', code: "KP" },
    { name: 'Corée du Sud', code: "KR" },
    { name: 'Costa Rica', code: "CR" },
    { name: 'Côte d\'Ivoire', code: "CI" },
    { name: 'Croatie', code: "HR" },
    { name: 'Cuba', code: "CU" },
    { name: 'Danemark', code: "DK" },
    { name: 'Djibouti', code: "DJ" },
    { name: 'Dominique', code: "DM" },
    { name: 'Égypte', code: "EG" },
    { name: 'Émirats arabes unis', code: "AE" },
    { name: 'Équateur', code: "EC" },
    { name: 'Érythrée', code: "ER" },
    { name: 'Espagne', code: "ES" },
    { name: 'Estonie', code: "EE" },
    { name: 'États-Unis', code: "US" },
    { name: 'Éthiopie', code: "ET" },
    { name: 'Fidji', code: "FJ" },
    { name: 'Finlande', code: "FI" },
    { name: 'France', code: "FR", currency: "€" },
    { name: 'Gabon', code: "GA" },
    { name: 'Gambie', code: "GM" },
    { name: 'Géorgie', code: "GE" },
    { name: 'Ghana', code: "GH" },
    { name: 'Grèce', code: "GR" },
    { name: 'Grenade', code: "GD" },
    { name: 'Guatemala', code: "GT" },
    { name: 'Guinée', code: "GN" },
    { name: 'Guinée-Bissau', code: "GW" },
    { name: 'Guinée équatoriale', code: "GQ" },
    { name: 'Guyana', code: "GY" },
    { name: 'Haïti', code: "HT" },
    { name: 'Honduras', code: "HN" },
    { name: 'Hongrie', code: "HU" },
    { name: 'Îles Marshall', code: "MH" },
    { name: 'Îles Salomon', code: "SB" },
    { name: 'Inde', code: "IN" },
    { name: 'Indonésie', code: "ID" },
    { name: 'Iran', code: "IR" },
    { name: 'Iraq', code: "IQ" },
    { name: 'Irlande', code: "IE" },
    { name: 'Islande', code: "IS" },
    { name: 'Italie', code: "IT" },
    { name: 'Jamaïque', code: "JM" },
    { name: 'Japon', code: "JP" },
    { name: 'Jordanie', code: "JO" },
    { name: 'Kazakhstan', code: "KZ" },
    { name: 'Kenya', code: "KE" },
    { name: 'Kirghizistan', code: "KG" },
    { name: 'Kiribati', code: "KI" },
    { name: 'Koweït', code: "KW" },
    { name: 'Laos', code: "LA" },
    { name: 'Lesotho', code: "LS" },
    { name: 'Lettonie', code: "LV" },
    { name: 'Liban', code: "LB" },
    { name: 'Liberia', code: "LR" },
    { name: 'Libye', code: "LY" },
    { name: 'Liechtenstein', code: "LI" },
    { name: 'Lituanie', code: "LT" },
    { name: 'Luxembourg', code: "LU" },
    { name: 'Macédoine du Nord', code: "MK" },
    { name: 'Madagascar', code: "MG" },
    { name: 'Malaisie', code: "MY" },
    { name: 'Malawi', code: "MW" },
    { name: 'Maldives', code: "MV" },
    { name: 'Mali', code: "ML" },
    { name: 'Malte', code: "MT" },
    { name: 'Maroc', code: "MA" },
    { name: 'Maurice', code: "MU" },
    { name: 'Mauritanie', code: "MR" },
    { name: 'Mexique', code: "MX" },
    { name: 'Micronésie', code: "FM" },
    { name: 'Moldavie', code: "MD" },
    { name: 'Monaco', code: "MC" },
    { name: 'Mongolie', code: "MN" },
    { name: 'Monténégro', code: "ME" },
    { name: 'Mozambique', code: "MZ" },
    { name: 'Namibie', code: "NA" },
    { name: 'Nauru', code: "NR" },
    { name: 'Népal', code: "NP" },
    { name: 'Nicaragua', code: "NI" },
    { name: 'Niger', code: "NE" },
    { name: 'Nigeria', code: "NG" },
    { name: 'Niue', code: "NU" },
    { name: 'Norvège', code: "NO" },
    { name: 'Nouvelle-Zélande', code: "NZ" },
    { name: 'Oman', code: "OM" },
    { name: 'Ouganda', code: "UG" },
    { name: 'Ouzbékistan', code: "UZ" },
    { name: 'Pakistan', code: "PK" },
    { name: 'Palaos', code: "PW" },
    { name: 'Panama', code: "PA" },
    { name: 'Papouasie-Nouvelle-Guinée', code: "PG" },
    { name: 'Paraguay', code: "PY" },
    { name: 'Pays-Bas', code: "NL" },
    { name: 'Pérou', code: "PE" },
    { name: 'Philippines', code: "PH" },
    { name: 'Pologne', code: "PL" },
    { name: 'Portugal', code: "PT" },
    { name: 'Qatar', code: "QA" },
    { name: 'Roumanie', code: "RO" },
    { name: 'Royaume-Uni', code: "GB" },
    { name: 'Russie', code: "RU" },
    { name: 'Rwanda', code: "RW" },
    { name: 'Saint-Christophe-et-Niévès', code: "KN" },
    { name: 'Saint-Marin', code: "SM" },
    { name: 'Saint-Vincent-et-les Grenadines', code: "VC" },
    { name: 'Sainte-Lucie', code: "LC" },
    { name: 'Îles Samoa', code: "WS" },
    { name: 'São Tomé-et-Principe', code: "ST" },
    { name: 'Sénégal', code: "SN" },
    { name: 'Serbie', code: "RS" },
    { name: 'Seychelles', code: "SC" },
    { name: 'Sierra Leone', code: "SL" },
    { name: 'Singapour', code: "SG" },
    { name: 'Slovaquie', code: "SK" },
    { name: 'Slovénie', code: "SI" },
    { name: 'Somalie', code: "SO" },
    { name: 'Soudan', code: "SD" },
    { name: 'Soudan du Sud', code: "SS" },
    { name: 'Sri Lanka', code: "LK" },
    { name: 'Suède', code: "SE" },
    { name: 'Suisse', code: "CH" },
    { name: 'Suriname', code: "SR" },
    { name: 'Eswatini', code: "SZ" },
    { name: 'Syrie', code: "SY" },
    { name: 'Tadjikistan', code: "TJ" },
    { name: 'Tanzanie', code: "TZ" },
    { name: 'Tchad', code: "TD" },
    { name: 'Thaïlande', code: "TH" },
    { name: 'Timor-Leste', code: "TL" },
    { name: 'Togo', code: "TG" },
    { name: 'Tonga', code: "TO" },
    { name: 'Trinité-et-Tobago', code: "TT" },
    { name: 'Tunisie', code: "TN", currency: "TND" },
    { name: 'Turkménistan', code: "TM" },
    { name: 'Turquie', code: "TR" },
    { name: 'Tuvalu', code: "TV" },
    { name: 'Ukraine', code: "UA" },
    { name: 'Uruguay', code: "UY" },
    { name: 'Vanuatu', code: "VU" },
    { name: 'Vatican', code: "VA" },
    { name: 'Venezuela', code: "VE" },
    { name: 'Viêt Nam', code: "VN" },
    { name: 'Yémen', code: "YE" },
    { name: 'Zambie', code: "ZM" },
    { name: 'Zimbabwe', code: "ZW" }
  ];
  
  export default countries;
  