import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import * as XLSX from "xlsx";
import millify from "millify";

import PptxGenJS from "pptxgenjs";

import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";

import { AppContext } from "../../../utils";
import dayjs from "dayjs";
import API from "../../../utils/Api";

import { ReactComponent as IconTarget } from "../../../images/svg/icon_target.svg";
import { ReactComponent as CalendarIcon } from "../../../images/images/calendar.svg";
import { ReactComponent as CalendarArrow } from "../../../images/images/calendarArrow.svg";
import { ReactComponent as CronIcon } from "../../../images/svg/refresh-ccw-01.svg";

import imageSlide1 from "../../../images/my_images/ppt/slide1.png";

import RapportPPT from "./RapportPPT";

const Header = () => {
  const [state, setState] = React.useContext(AppContext);
  const [status, setStatus] = useState("");

  const [selectedExport, setSelectedExport] = useState("");

  const [imageData, setImageData] = useState(null);
  const [cronActivationSuccess, setCronActivationSuccess] = useState("");
  const [visible, setVisible] = useState(false); // Modifiez cette valeur à true pour l'ouverture automatique

  const pptx = new PptxGenJS();

  const [dates2, setDates2] = useState(
    state.reportingPeriod || [
      dayjs().subtract(1, "month").toDate(),
      dayjs().toDate(),
    ]
  );
  const { t, i18n } = useTranslation();

  let userData = state.userData || {};
  //console.log('userData', userData);
  let name_user = userData.name;
  let name_page = state.currentPage.page.name;
  let picture_page = state.currentPage.page.picture;
  //console.log('props Header =>', state);
  /*----------------- export data for excel */

  let kpis_data = state?.currentKpis;
  let interaction_total = 0;
  let data_interaction = kpis_data?.total?.posts_type_interactions ?? [];
  for (let key in data_interaction) {
    interaction_total += data_interaction[key];
  }

  let posts_reach_total = kpis_data?.total?.posts_reach;
  let tauxEng = posts_reach_total
    ? ((interaction_total / posts_reach_total) * 100).toLocaleString() + "%"
    : 0;

  const totalKpis = {
    total_fans: kpis_data?.total?.page_fans,
    nouveaux_fans: kpis_data?.total?.page_fan_adds,
    fans_perdus: kpis_data?.total?.page_fan_removes,
    nouveaux_fans_payants: kpis_data?.total?.page_fan_adds_total?.Paid,
    nouveaux_fans_organiques: kpis_data?.total?.page_fan_adds_total?.Organic,
    fans_engager: kpis_data?.total?.page_engaged_users,
    taux_engagement: tauxEng,
    portée_de_page: 0,
    nombre_de_post: kpis_data?.total?.posts_count,
    portée_des_posts: posts_reach_total,
    portée_organique_des_posts: kpis_data?.total?.posts_reach_organic,
    portée_payante: kpis_data?.total?.posts_reach_paid,
    total_engagement: interaction_total,
    réactions: data_interaction?.Réactions,
    comments: data_interaction?.Commentaires,
    shares: data_interaction?.Partages,
    clics: data_interaction?.Clics,
    nombre_de_vidéos: kpis_data?.videos?.total?.count_videos,
    total_des_vues: kpis_data?.videos?.total?.total_video_views,
    vues_organiques: kpis_data?.videos?.total?.total_video_views_organic,
    vues_payantes: kpis_data?.videos?.total?.total_video_views_paid,
    lectures_automatiques:
      kpis_data?.videos?.total?.total_video_views_autoplayed,
    lectures_par_clics:
      kpis_data?.videos?.total?.total_video_views_clicked_to_play,
  };

  const dataDay = kpis_data?.by_day || [];
  const videoDay = kpis_data?.videos?.by_day || [];

  const DataArray = Object.keys(dataDay).map((key) => {
    const pageFansPaidUnpaid =
      dataDay[key].page_fan_adds_by_paid_non_paid_unique;
    const pageFansPaidUnpaidData = pageFansPaidUnpaid
      ? JSON.parse(pageFansPaidUnpaid)
      : null;

    return [
      key,
      dataDay[key].page_fans ?? 0,
      (pageFansPaidUnpaidData && pageFansPaidUnpaidData["total"]) || 0,
      //dataDay[key].page_fan_adds ?? 0,
      dataDay[key].page_fan_removes ?? 0,
      (pageFansPaidUnpaidData && pageFansPaidUnpaidData["paid"]) || 0,
      (pageFansPaidUnpaidData && pageFansPaidUnpaidData["unpaid"]) || 0,
      dataDay[key].page_fans_online_per_day ?? 0,
      dataDay[key].page_engaged_users ?? 0,
      dataDay[key].posts_reach
        ? (
            ((dataDay[key].posts_interactions + dataDay[key].posts_clicks) /
              dataDay[key].posts_reach) *
            100
          ).toLocaleString() + "%"
        : 0,
      dataDay[key].posts_count ?? 0,
      dataDay[key].posts_reach ?? 0,
      dataDay[key].posts_reach_organic ?? 0,
      dataDay[key].posts_reach_paid ?? 0,
      dataDay[key].posts_interactions ?? 0,
      (dataDay[key].posts_interactions ?? 0) + (dataDay[key].posts_clicks ?? 0),
      dataDay[key].posts_reactions ?? 0,
      dataDay[key].posts_comments ?? 0,
      dataDay[key].posts_shares ?? 0,
      dataDay[key].posts_clicks ?? 0,
    ];
  });

  const VideoArray = Object.keys(videoDay).map((key) => [
    key,
    videoDay[key].count_video ?? 0,
    videoDay[key].total_video_views ?? 0,
    videoDay[key].total_video_views_organic ?? 0,
    videoDay[key].total_video_views_paid ?? 0,
    videoDay[key].total_video_views_autoplayed ?? 0,
    videoDay[key].total_video_views_clicked_to_play ?? 0,
  ]);

  const mergedDataArray = DataArray.map((dataRow) => {
    const matchingVideoRow = VideoArray.find(
      (videoRow) => videoRow[0] === dataRow[0]
    );

    if (matchingVideoRow) {
      return [
        dataRow[0],
        ...dataRow.slice(1), // DataArray values
        ...matchingVideoRow.slice(1), // VideoArray values
      ];
    } else {
      return dataRow; // If there is no matching VideoArray row, keep DataArray row as-is
    }
  });

  const kpisArrayMonth = Object.entries(kpis_data.export.by_month).map(
    ([key, values]) => [key, ...Object.values(values)]
  );

  const export_rapport = [
    { name: "Excel", code: "xls" },
    // { name: 'PowerPoint', code: 'ppt' }
  ];

  const keys = Object.keys(totalKpis);
  const formattedKeys = keys.map((key) => {
    // Remplacez _ par un espace et mettez la première lettre en majuscule
    return key.replace(/_/g, " ").replace(/^\w/, (c) => c.toUpperCase());
  });

  const values = keys.map((key) => totalKpis[key]);

  const total_kpis = [formattedKeys, values];

  const header_kpis_day = [
    "Date",
    "Total fans",
    "Nouveaux fans",
    "Fans perdus",
    "Nouveaux fans payants",
    "Nouveaux fans organiques",
    "Fans online",
    "Fans engager",
    "Taux engagement",
    "Portée de page",
    "Nombre de post",
    "Portée des posts",
    "Portée organique des posts",
    "Portée payante",
    "Interaction",
    "Total engagement",
    "Réactions",
    "Comments",
    "Shares",
    "Clics",
    "Nombre de vidéos",
    "Total des vues",
    "Vues organiques",
    "Vues payantes",
    "Lectures automatiques",
    "Lectures par clics",
  ];
  const kpis_by_day = [header_kpis_day, ...mergedDataArray];

  const kpis_by_month = [header_kpis_day, ...kpisArrayMonth];

  const exportToExcel = () => {
    const wb = XLSX.utils.book_new();
    const ws1 = XLSX.utils.aoa_to_sheet(total_kpis);
    const ws2 = XLSX.utils.aoa_to_sheet(kpis_by_day);
    const ws3 = XLSX.utils.aoa_to_sheet(kpis_by_month);

    XLSX.utils.book_append_sheet(wb, ws1, "TOTAL KPIS");
    XLSX.utils.book_append_sheet(wb, ws2, "KPIS BY DAY");
    XLSX.utils.book_append_sheet(wb, ws3, "KPIS BY MONTH");

    // Create an Excel file and trigger the download
    XLSX.writeFile(wb, "exported_data.xlsx");
  };

  const handleDropdownChange = async (e) => {
    setSelectedExport(e.value);
    if (e.value && e.value.code && e.value.code === "xls") {
      exportToExcel();
    }
  };

  const onHide = () => {
    setVisible(false);
  };

  const refreshPage = () => {
    window.location.href = `/reporting/${state.currentPage.page_id}`;
  };

  const footerContent = (
    <button
      className="p-button p-component p-button-warning table !m-auto"
      onClick={refreshPage}
    >
      <span className="">{t("Performance_report.refresh_page")}</span>
    </button>
  );

  const cronEvent = async () => {
    const dataBench = state?.currentKpis?.dataBench?.total;
    const date_info = state.currentKpis.date_info.start_date;

    // Loop through the list and trigger cron job for each page_id
    for (const list_page of dataBench) {
      const page_id = list_page.page_id;
      const managed = list_page.managed === 1 ? "managed" : "unmanaged";
      console.log("list_page", list_page);
      console.log(`Activating cron for page_id: ${list_page.page_id}`);

      setCronActivationSuccess(true);
      try {
        const response = await API.pages.cronActivate(
          page_id,
          managed,
          date_info
        );
        console.log(response.data);

        // Set the state to indicate success for each page_id
        setCronActivationSuccess(false);
      } catch (error) {
        console.error(`Error for page_id ${list_page.page_id}:`, error);
        // Handle errors if needed
      } finally {
        // Reset the state to indicate success or failure
        setCronActivationSuccess(true);
      }
    }

    setVisible(true);
    console.log("Cron activation for all page_ids completed.");
  };

  return (
    <div>
      {/* <RapportPPT state={state} /> */}

      <Dialog
        header=""
        visible={visible}
        position="top"
        style={{ width: "50vw" }}
        onHide={onHide}
        footer={footerContent}
        draggable={false}
        resizable={false}
      >
        <p className="text-[#101828] font-semibold text-[24px] text-center">
          {t("Performance_report.data_updated")}
        </p>
      </Dialog>

      <div className="flex items-center">
        <div className="name_user">
          <h1 className="text-[#101828] font-semibold text-[30px] flex items-center">
            <img className="w-10 h-10 rounded-[10px] mr-2" src={picture_page} />
            {name_page}
          </h1>
        </div>

        <div className="flex items-center ml-auto">
          <div className="ml-auto mr-4 border border-[#d0d5dd] rounded-[6px] bg-white">
            <Dropdown
              showClear
              optionLabel="name"
              value={selectedExport}
              placeholder={t("Performance_report.report_export")}
              options={export_rapport}
              className="w-full md:w-14rem text-[#344054] text-[14px] font-semibold"
              onChange={handleDropdownChange}
            />
          </div>

          {state.userData.id && (
            <button
              className={`ml-auto mr-4 px-4 py-3 border border-[#d0d5dd] rounded-[6px] bg-white`}
              onClick={cronEvent}
            >
              <CronIcon
                className={`${cronActivationSuccess ? "rotate-animation" : ""}`}
              />
            </button>
          )}

          <button className="ml-auto flex items-center text-white bg-[#7f56d9] shadow-[0_1px_2px_0_rgba(16,24,40,0.05)] rounded-[8px] px-4 py-3 hide_el">
            <IconTarget className="mr-2" />
            Add new target
          </button>
        </div>
      </div>

      <div className="justify-between flex mt-4 items-center text-sm 2xl:text-base">
        {/* calendar */}
        <div className="flex items-center gap-6">
          <div className="flex bg-white border-gray-300  border  items-center rounded font-CityOfEmber   calendar-navbar w-72  ">
            <label
              htmlFor="date_range"
              className="cursor-pointer pl-6 dark:text-asap-white text-asap-blue"
            >
              <CalendarIcon />
            </label>
            <Calendar
              inputId="date_range"
              dateFormat="yy/mm/dd"
              panelClassName="mt-6 bg-asap-gray-900 p-2 font-CityOfEmber text-gray-900 font-bold  "
              className="font-CityOfEmber text-gray-900 font-bold "
              inputClassName=" ml-2 cursor-pointer   outline-none  text-asap-blue text-sm text-gray-900 font-semibold "
              id="range"
              value={dates2}
              onChange={(e) => {
                setDates2(e.value);
                if (e.value[0] != null && e.value[1] != null)
                  setState({ ...state, reportingPeriod: e.value });
              }}
              selectionMode="range"
              readOnlyInput
              numberOfMonths={1}
            />
            <label
              htmlFor="date_range"
              className=" pl-2 cursor-pointer dark:text-asap-white text-asap-blue"
            >
              <CalendarArrow />
            </label>
          </div>
          <div className="flex gap-2">
            <span>{t("Performance_report.period")}</span>
            {dates2[0] && (
              <div className="flex gap-2">
                <span>{dates2[0]?.toDateString()}</span> -
                <span>{dates2[1]?.toDateString()}</span>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
