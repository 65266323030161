import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { InputText } from "primereact/inputtext";
import { OverlayPanel } from "primereact/overlaypanel";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import millify from "millify";

import dayjs from "dayjs";
import { VirtualScroller } from "primereact/virtualscroller";

import { AppContext, SocialIcon, useUtils, Loader } from "../../utils";
import API from "../../utils/Api";
import toast from "react-hot-toast";

import Skeleton from "./Skeleton";
import SkeletonTiktok from "./SkeletonTiktok";

import dropdownIcon from "../../images/dropdown1.png";
import { ReactComponent as CalendarIcon } from "../../images/images/calendar.svg";
import { ReactComponent as FansIcon } from "../../images/svg/icon_total_fans.svg";
import { ReactComponent as CalendarArrow } from "../../images/images/calendarArrow.svg";

import * as XLSX from "xlsx";

let delayTimer;
let postsToRefresh = [];

const PostLibrary = () => {
  const [status, setStatus] = useState("loading");
  const [state, setState] = React.useContext(AppContext);
  const { t, i18n } = useTranslation();
  let currentPage = state.currentPage || {};

  //-- tiktok
  const tiktok_data = state?.currentKpis?.tiktok;

  //console.log("state", tiktok_data);
  const [posts, setPosts] = useState();
  const [getKeys, setKeys] = useState();
  const [getImage, setImage] = useState();
  const [inputSearch, setInputSearch] = useState("");
  const [searchData, setSearchData] = useState("");
  const [searchValue, setSearchValue] = useState("");

  const [visibleInputId, setVisibleInputId] = useState(null);

  const [contentType, setContentType] = useState("");
  const [textTag, setTextTag] = useState("");
  const [formatType, setFormatType] = useState("");
  const [performance, setPerformance] = useState("");
  const [promotedPost, setPromotedPost] = useState("");
  const [formSearch, setFormSearch] = useState("");

  const initialInputState = posts
    ? posts.reduce((acc, post) => {
        acc[post.id] = "";
        return acc;
      }, {})
    : {};

  const [inputValues, setInputValues] = useState(initialInputState);

  const op = useRef(null);
  const toast = useRef(null);

  const user_id = currentPage?.user_id;

  const [dates2, setDates2] = useState(
    state.reportingPeriod || [
      dayjs().subtract(1, "year").toDate(),
      dayjs().toDate(),
    ]
  );

  const loadPosts = async () => {
    try {
      setStatus("loading");
      const res = await API.pages.getPosts(currentPage.id, {
        user_id: user_id,
        from: dayjs(state.reportingPeriod[0]).format("YYYY-MM-DD"),
        to: dayjs(state.reportingPeriod[1]).format("YYYY-MM-DD"),
        searchValue,
        formSearch,
      });
      // res.data.data.forEach(el=>el.picture='https://www.google.com/images/branding/googlelogo/1x/googlelogo_color_272x92dp.png111');
      setPosts(res.data?.data);
      setKeys(res.data?.all_keys);
      setSearchData(res.data?.data_search);
      setStatus("");
    } catch (e) {
      console.log(e);
      toast.error("Somethong went wrong, please try again.");
    }
  };

  const fetchImages = async (pageId, socialId) => {
    try {
      const response = await API.pages.getPageImage(pageId, socialId);
      console.log(response.data);
      setImage(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    loadPosts();
  }, [state.reportingPeriod, searchValue, formSearch]);

  let code_view = "total_video_views";
  if (currentPage.social_type == "instagram") code_view = "views";
  //console.log('setPosts =>', posts);

  let sortCriteriasOptions =
    currentPage.social_type === "youtube"
      ? [
          {
            name: t("Post_Library.Les plus récents"),
            code: "date_youtube",
            asc: false,
          },
          {
            name: t("Post_Library.Les plus engageants"),
            code: "total_engagement",
            asc: false,
          },
          {
            name: t("Post_Library.Les plus likés"),
            code: "reactions",
            asc: false,
          },
          { name: t("Post_Library.top_viewed"), code: "views_youtube", asc: false },
          {
            name: t("Post_Library.top_commented"),
            code: "comments",
            asc: false,
          },
          {
            name: t("Post_Library.Les moins likés"),
            code: "reactions",
            asc: true,
          },
          { name: t("Post_Library.Least_Recent"), code: "date_youtube", asc: true },
          {
            name: t("Post_Library.Les moins engageants"),
            code: "total_engagement",
            asc: true,
          },
          {
            name: t("Post_Library.Les moins commentés"),
            code: "comments",
            asc: true,
          },
          { name: t("Post_Library.least_viewed"), code: "views_youtube", asc: true },
        ]
      : [
          {
            name: t("Post_Library.Les plus récents"),
            code: "date",
            asc: false,
          },
          {
            name: t("Post_Library.top_engagement_rate"),
            code: "taux_engagement",
            asc: false,
          },
          {
            name: t("Post_Library.Les plus engageants"),
            code: "total_engagement",
            asc: false,
          },
          {
            name: t("Post_Library.Les plus Téléchargés"),
            code: "download",
            asc: false,
          },
          {
            name: t("Post_Library.Les plus partagés"),
            code: "shares",
            asc: true,
          },
          { name: t("Post_Library.top_reached"), code: "reach", asc: false },
          {
            name: t("Post_Library.Les plus likés"),
            code: "reactions",
            asc: false,
          },
          { name: t("Post_Library.top_viewed"), code: code_view, asc: false },
          { name: t("Post_Library.top_clicked"), code: "clicks", asc: true },
          {
            name: t("Post_Library.top_commented"),
            code: "comments",
            asc: false,
          },
          {
            name: t("Post_Library.Les moins likés"),
            code: "reactions",
            asc: true,
          },
          { name: t("Post_Library.Least_Recent"), code: "mdate", asc: false },
          {
            name: t("Post_Library.Least_Engaging_Rates"),
            code: "taux_engagement",
            asc: true,
          },
          {
            name: t("Post_Library.Les moins engageants"),
            code: "total_engagement",
            asc: true,
          },
          {
            name: t("Post_Library.Les moins Téléchargés"),
            code: "download",
            asc: true,
          },
          {
            name: t("Post_Library.Les moins partagés"),
            code: "shares",
            asc: false,
          },
          { name: t("Post_Library.Least_Reached"), code: "reach", asc: true },
          {
            name: t("Post_Library.Les moins commentés"),
            code: "comments",
            asc: true,
          },
          { name: t("Post_Library.least_viewed"), code: code_view, asc: true },
        ];

  if (currentPage.social_type === "tiktok") {
    const excludedCodes = ["taux_engagement", "clicks", "reach"];
    sortCriteriasOptions = sortCriteriasOptions.filter(
      (option) => !excludedCodes.includes(option.code)
    );
  } else {
    const excludedCodes = ["download"];
    sortCriteriasOptions = sortCriteriasOptions.filter(
      (option) => !excludedCodes.includes(option.code)
    );
  }

  const filterCriteriasOptions = [
    { name: t("advanced_search.media_type"), code: "" },
    { name: t("Post_Library.photos"), code: "photo" },
    { name: t("Post_Library.vidéos"), code: "video" },
    { name: t("Post_Library.Autres"), code: "other" },
  ];

  const [sortCriteria, setSortCriteria] = useState(sortCriteriasOptions[0]);
  const [filterCriteria, setFilterCriteria] = useState(
    filterCriteriasOptions[0]
  );
  const onSortChange = (e) => {
    console.log(e);
    setSortCriteria(e.value);
  };
  const onFilterChange = (e) => {
    setFilterCriteria(e.value);
  };

  const filterTypeOptions = [{ name: t("Post_Library.post_type"), code: "" }];

  if (currentPage.social_type === "facebook") {
    filterTypeOptions.push(
      { name: "Posts", code: "posts" },
      { name: "Reels", code: "reals" },
      { name: "Stories", code: "stories" }
    );
  } else if (currentPage.social_type === "instagram") {
    filterTypeOptions.push(
      { name: "Feeds", code: "FEED" },
      { name: "Reels", code: "REELS" },
      { name: "Stories", code: "STORY" }
    );
  } else if (currentPage.social_type === "youtube") {
    filterTypeOptions.push(
      { name: "Videos", code: "VIDEO" },
      { name: "Shorts", code: "SHORT" },
      { name: "Live", code: "LIVE" }
    );
  }

  const [filterStatusType, setfilterStatusType] = useState(
    filterTypeOptions[0]
  );
  const onFilterTypeChange = (e) => {
    setfilterStatusType(e.value);
  };

  //-- search
  const handleSeachChange = (e) => {
    setInputSearch(e.target.value);
    if (e.target.value == "") {
      setSearchValue("");
    }
  };

  const handleKeyPress = async (e) => {
    if (e.key === "Enter") {
      setSearchValue(inputSearch);
    }
  };

  /*const postsToRender = (posts || [])
    .filter((el) => {
      
      if (inputSearch && el.tag && el.message) {
        return el.tag.includes(inputSearch) || el.message.includes(inputSearch);
      } 
      if (inputSearch && el.tag) {
          return el.tag.includes(inputSearch);
      } 
      if (inputSearch && el.message) {
          return el.message.includes(inputSearch);
      }

      // Conditions de filtrage par status type
      if (!filterStatusType || !filterStatusType.code) return true;
      
      if (filterStatusType.code == "FEED") {
          return el.status_type == filterStatusType.code;
      }
      
      if (filterStatusType.code == "REELS") {
        return el.status_type == filterStatusType.code;
      }
      
      if (filterStatusType.code == "STORY") {
        return el.status_type== filterStatusType.code;
      }
      
      // Conditions de filtrage
      if (!filterCriteria || !filterCriteria.code) return true;
      
      if (filterCriteria.code == "photo") {
          return el.postType == "photo" ? el.postType == "photo" : (el.status_type == "added_photos" || el.type == "IMAGE" || el.status_type == "storie_photo");
      }
      
      if (filterCriteria.code == "video") {
          return el.postType == "video" ? el.postType == "video" : (el.status_type == "added_video" || el.type == "VIDEO" || el.status_type == "storie_video");
      }
      
      if (filterCriteria.code == "other") {
          return el.postType != "photo" && el.postType != "video";
      }
      
      if (filterCriteria.code != "other") {
          if (el.postType) {
              return el.postType.toLowerCase() == filterCriteria.code;
          } else {
              return el.postType != "photo" && el.postType != "video";
          }
      }
    
    });*/

  const postsToRender = (posts || []).filter((el) => {
    // Fonction pour le filtre de status type
    const statusTypeFilter = () => {
      if (!filterStatusType || !filterStatusType.code) return true;

      if (currentPage.social_type === "facebook") {
        switch (filterStatusType.code) {
          case "posts":
            return el.type != "storie" && el.is_real != 1;
          case "reals":
            return el.is_real == 1;
          case "stories":
            return el.type == "storie";
          default:
            return true;
        }
      }

      if (currentPage.social_type === "instagram") {
        switch (filterStatusType.code) {
          case "FEED":
          case "REELS":
          case "STORY":
            return el.status_type == filterStatusType.code;
          default:
            return true;
        }
      }

      if (currentPage.social_type === "youtube") {
        console.log("heiii : ", el);
        switch (filterStatusType.code) {
          case "VIDEO":
            return el.type === "VIDEO";
          case "SHORT":
            return el.type === "SHORT";
          case "LIVE":
            return el.type === "LIVE";
          default:
            return true;
        }
      }

      return true;
    };

    // Fonction pour le filtre de critères
    const criteriaFilter = () => {
      if (!filterCriteria || !filterCriteria.code) return true;

      if (currentPage.social_type === "tiktok") {
        switch (filterCriteria.code) {
          case "photo":
            return el.post_type == "photo";
          case "video":
            return el.post_type == "video";
          case "other":
            return el.post_type != "photo" && el.post_type != "video";
          default:
            return true;
        }
      } else {
        switch (filterCriteria.code) {
          case "photo":
            return el.postType == "photo"
              ? el.postType == "photo"
              : el.status_type == "added_photos" ||
                  el.type == "IMAGE" ||
                  el.status_type == "storie_photo";
          case "video":
            return el.postType == "video"
              ? el.postType == "video"
              : el.status_type == "added_video" ||
                  el.type == "VIDEO" ||
                  el.status_type == "storie_video";
          case "other":
            return el.postType != "photo" && el.postType != "video";
          default:
            if (el.postType) {
              return el.postType.toLowerCase() == filterCriteria.code;
            } else {
              return el.postType != "photo" && el.postType != "video";
            }
        }
      }
    };

    // Appliquer les filtres
    return statusTypeFilter() && criteriaFilter();
  });

  // Nombre de messages à afficher
  const numberOfPosts = postsToRender.length;

  const sortedPosts = postsToRender.sort((a, b) => {
    if (!sortCriteria || !sortCriteria.code) return 0;

    let c = a[sortCriteria.code] > b[sortCriteria.code] ? -1 : 1;

    if (sortCriteria.code == "date_youtube")
      c = a.creation_time > b.creation_time ? 1 : -1;
    if (sortCriteria.code == "date" && currentPage.social_type == "tiktok") {
      c = a.day > b.day ? 1 : -1;
    }

    if (sortCriteria.code == "mdate") {
      if (currentPage.social_type == "tiktok") {
        c = a.day > b.day ? 1 : -1;
      } else {
        c = a[sortCriteria.code] > b[sortCriteria.code] ? 1 : -1;
      }
    }
    if (sortCriteria.code == "comments") {
      if (currentPage.social_type == "tiktok") {
        c = +a.video_total_comments > +b.video_total_comments ? 1 : -1;
      }else if(currentPage.social_type == "youtube") {
        c = +a[sortCriteria.code] > +b[sortCriteria.code] ? 1 : -1;
      } else {
        c = a[sortCriteria.code] > b[sortCriteria.code] ? 1 : -1;
      }
    }

    if (sortCriteria.code == "share") {
      if (currentPage.social_type == "tiktok") {
        c = +a.video_total_shares > +b.video_total_shares ? 1 : -1;
      } else {
        c = a[sortCriteria.code] > b[sortCriteria.code] ? 1 : -1;
      }
    }

    let reach_post_a = 0;
    let reach_post_b = 0;
    if (sortCriteria.code == "reach") {
      if (currentPage.social_type == "instagram") {
        reach_post_a = a.reach + (a.reach_ad || 0);
        reach_post_b = b.reach + (b.reach_ad || 0);
      } else {
        reach_post_a = a.reach_paid + a.reach_organic + (a.reach_ad || 0);
        reach_post_b = b.reach_paid + b.reach_organic + (b.reach_ad || 0);
      }
      c = reach_post_a > reach_post_b ? 1 : -1;
    }

    if (sortCriteria.code == "reactions") {
      if (currentPage.social_type == "tiktok" || currentPage.social_type == "youtube") {
        c = +a.video_likes > +b.video_likes ? 1 : -1;
      } else {
        let reactions_a =
          a.likes +
          (a.likes_ad || 0) +
          a.wow +
          a.sad +
          a.haha +
          a.angry +
          a.none +
          a.love +
          a.thankful +
          a.saved +
          (a.saved_ad || 0);
        let reactions_b =
          b.likes +
          (b.likes_ad || 0) +
          b.wow +
          b.sad +
          b.haha +
          b.angry +
          b.none +
          b.love +
          b.thankful +
          b.saved +
          (b.saved_ad || 0);
        c = reactions_a > reactions_b ? 1 : -1;
      }
    }

    function compareEngagement(a, b) {
      const tolerance = 1e-5; // Tolérance pour la comparaison
      if (Math.abs(a - b) < tolerance) {
        return 0;
      } else {
        return a > b ? 1 : -1;
      }
    }

    function calculateEngagement(obj) {
      const reactions =
        obj.likes +
        (obj.likes_ad || 0) +
        obj.wow +
        obj.sad +
        obj.haha +
        obj.angry +
        obj.none +
        obj.love +
        obj.thankful +
        obj.saved +
        (obj.saved_ad || 0);
      const reach = obj.reach;
      const engagement = reach
        ? (obj.clicks +
            obj.comments +
            (obj.comments_ad || 0) +
            obj.shares +
            reactions +
            (obj.shares_ad || 0)) /
          reach
        : 0;
      return parseFloat(engagement.toFixed(5)); // Arrondir à 5 décimales
    }

    function calculateTotalEngagement(obj) {
      const reactions =
        obj.likes +
        (obj.likes_ad || 0) +
        obj.wow +
        obj.sad +
        obj.haha +
        obj.angry +
        obj.none +
        obj.love +
        obj.thankful +
        obj.saved +
        (obj.saved_ad || 0);
      const engagement =
        obj.clicks +
        obj.comments +
        (obj.comments_ad || 0) +
        obj.shares +
        reactions +
        (obj.shares_ad || 0);
      return parseFloat(engagement); // Arrondir à 5 décimales
    }

    if (sortCriteria.code == "taux_engagement") {
      if (currentPage.social_type == "instagram") {
        let reactions_a =
          a.likes +
          (a.likes_ad || 0) +
          a.wow +
          a.sad +
          a.haha +
          a.angry +
          a.none +
          a.love +
          a.thankful +
          a.saved +
          (a.saved_ad || 0);
        let reactions_b =
          b.likes +
          (b.likes_ad || 0) +
          b.wow +
          b.sad +
          b.haha +
          b.angry +
          b.none +
          b.love +
          b.thankful +
          b.saved +
          (b.saved_ad || 0);
        let engagement_a = a.reach
          ? (a.clicks + a.comments + a.shares + reactions_a) / a.reach
          : 0;
        let engagement_b = b.reach
          ? (b.clicks + b.comments + b.shares + reactions_b) / b.reach
          : 0;
        c = engagement_a > engagement_b ? 1 : -1;
      } else {
        const engA = calculateEngagement(a);
        const engB = calculateEngagement(b);

        c = compareEngagement(engA, engB);
      }
    }

    if (sortCriteria.code == "total_engagement") {
      if (currentPage.social_type == "instagram") {
        let reactions_a =
          a.likes +
          (a.likes_ad || 0) +
          a.wow +
          a.sad +
          a.haha +
          a.angry +
          a.none +
          a.love +
          a.thankful +
          a.saved +
          (a.saved_ad || 0);
        let reactions_b =
          b.likes +
          (b.likes_ad || 0) +
          b.wow +
          b.sad +
          b.haha +
          b.angry +
          b.none +
          b.love +
          b.thankful +
          b.saved +
          (b.saved_ad || 0);
        let engagement_a = a.clicks + a.comments + a.shares + reactions_a;
        let engagement_b = b.clicks + b.comments + b.shares + reactions_b;
        c = engagement_a > engagement_b ? 1 : -1;
      } else if (currentPage.social_type == "tiktok") {
        let reactions_a =
          +a.video_likes +
          +a.video_views +
          +a.video_total_shares +
          +a.video_total_comments +
          +a.video_total_downloads;

        let reactions_b =
          +b.video_likes +
          +b.video_views +
          +b.video_total_shares +
          +b.video_total_comments +
          +b.video_total_downloads;
        c = reactions_a > reactions_b ? 1 : -1;
      } else if (currentPage.social_type == "youtube") {
        let reactions_a = (+a.comments || 0) + (+a.views || 0);
        let reactions_b = (+b.comments || 0) + (+b.views || 0);
        c = reactions_a > reactions_b ? 1 : -1;
      } else {
        const engA = calculateTotalEngagement(a);
        const engB = calculateTotalEngagement(b);

        c = compareEngagement(engA, engB);
      }
    }

    if (currentPage.social_type == "instagram") {
      if (sortCriteria.code == "views") {
        c =
          a[sortCriteria.code] + (a.views_ad || 0) >
          b[sortCriteria.code] + (b.views_ad || 0)
            ? 1
            : -1;
      }
    } else if (currentPage.social_type == "tiktok") {
      c = +a.video_views > +b.video_views ? 1 : -1;
    } else {
      if (sortCriteria.code == "total_video_views") {
        let totalVideoViews_a = 0;
        const parsedData_a = a && a.data ? JSON.parse(a.data) : null;
        totalVideoViews_a = parsedData_a?.data?.total_video_views ?? 0;

        let totalVideoViews_b = 0;
        const parsedData_b = b && b.data ? JSON.parse(b.data) : null;
        totalVideoViews_b = parsedData_b?.data?.total_video_views ?? 0;
        c = totalVideoViews_a > totalVideoViews_b ? 1 : -1;
      }
    }

    if (sortCriteria.code == "views_youtube"){
      c = +a.views > +b.views ? 1 : -1;
    }
    if (
      currentPage.social_type == "tiktok" &&
      sortCriteria.code == "download"
    ) {
      c = +a.video_total_downloads > +b.video_total_downloads ? 1 : -1;
    }

    return c * (sortCriteria.asc ? 1 : -1);
  });

  function refreshPosts(el) {
    postsToRefresh.push(el.id);
    if (delayTimer) return;
    console.log("el", el);
    const image_static =
      "page_" + currentPage.page_id + "/" + el.social_id + ".jpg";
    delayTimer = setTimeout(async function () {
      //fetchImages(currentPage.page_id, el.social_id)
      try {
        const res = await API.pages.refreshPost(currentPage.id, postsToRefresh);
        res.data.forEach(
          (el) =>
            // changePostAttribute(el.id, "picture", el.picture)
            //changePostAttribute(el.id, null, { ...el, picture:'https://buffer.com/cdn-cgi/image/w=1000,fit=contain,q=90,f=auto/library/content/images/size/w1200/2023/10/free-images.jpg'})
            changePostAttribute(el.id, null, { ...el, picture: el.picture })
          //changePostAttribute(el.id, null, { ...el, picture: getImage.url })
        );
        //console.log("refreshed", res.data);
      } catch (e) {
        console.log(e);
      }
      delayTimer = null;
      postsToRefresh = [];
    }, 1000);
  }

  let changePostAttribute = (postId, attribute, value) => {
    //console.log("changePostAttribute", postId, attribute, value);
    let idx = posts.findIndex((el) => el.id == postId);
    if (!attribute) posts[idx] = value;
    else posts[idx][attribute] = value;
    setPosts(Object.assign([], posts));
    //console.log('value', value);
    if (attribute == "status" && value == "error") {
      //console.log('error posts[idx] =>', posts[idx].social_id);
      //fetchImages(currentPage.page_id, posts[idx].social_id);
      //refreshPosts(posts[idx]);
    }
  };

  //-- Score
  const score_color = {
    "A+": "#8ed55c",
    A: "#4a932b",
    B: "#f9cb33",
    C: "#f5c499",
    D: "#e5e5e5",
  };

  const tpl = (post) => {
    //-- url
    let url_post = "";
    if (currentPage.social_type == "facebook") {
      url_post = "https://www.facebook.com/" + post.social_id;
    } else if (currentPage.social_type == "instagram") {
      url_post = post.url;
    } else if (currentPage.social_type == "youtube") {
      url_post = "https://www.youtube.com/watch?v=" + post.video_id;
    }
    //-- views
    let totalVideoViews = 0;
    if (currentPage.social_type === "instagram") {
      totalVideoViews = post?.views ? post.views : 0;
    } else if (currentPage.social_type === "youtube") {
      totalVideoViews = post?.views | 0;
    } else {
      const parsedData = post && post.data ? post.data : null;
      const json =
        typeof parsedData === "object" ? parsedData : JSON.parse(parsedData);
      totalVideoViews = json?.data?.total_video_views ?? 0;
    }

    //-- metrics storie
    if (currentPage.social_type === "instagram") {
      var parsedData = post && post.data ? post.data : null;
      var dataJson =
        typeof parsedData === "object" ? parsedData : JSON.parse(parsedData);
      var post_reach = post?.reach ?? 0;
      var total_views = post?.views ?? 0;
      var post_partage = post?.shares ?? 0;
      var replies = dataJson?.replies ?? 0;
      var tap_exit = dataJson?.dimension_values?.tap_exit ?? 0;
      var tap_back = dataJson?.dimension_values?.tap_back ?? 0;
      var total_interactions = dataJson?.total_interactions ?? 0;
      var tap_forward = dataJson?.dimension_values?.tap_forward ?? 0;
      var swipe_forward = dataJson?.dimension_values?.swipe_forward ?? 0;
    }

    let show_data = true;
    if (currentPage.social_type == "facebook" && post.type == "storie") {
      show_data = false;
    } else if (
      currentPage.social_type == "instagram" &&
      post.status_type == "STORY"
    ) {
      show_data = false;
    }

    const handleButtonClick = () => {
      setVisibleInputId(post.id);
    };

    const handleChange = (e) => {
      const newValue = e.target.value;
      setInputValues((prevInputValues) => ({
        ...prevInputValues,
        [visibleInputId]: newValue,
      }));
    };

    //console.log('post', post);
    const accept = async (postId) => {
      const value = inputValues[postId];

      if (!value) {
        const inputElement = document.getElementById(`input_${postId}`);
        inputElement.classList.add("border-[#e24c4c]");

        toast.current.show({
          severity: "error",
          summary: "Merci",
          detail: "D'ajouter un tag",
          life: 3000,
        });
      } else {
        setVisibleInputId(null);
        setInputValues((prevInputValues) => ({
          ...prevInputValues,
          [postId]: "", // Clearing the input value
        }));

        try {
          const response = await API.pages.tag_save(postId, value);
          toast.current.show({
            severity: "success",
            summary: "Tag",
            detail: "Ajouté avec succès",
            life: 3000,
          });
          console.log(response.data);
        } catch (error) {
          console.error(error);
        }

        console.log("Post :", postId);
        console.log("Accepted value:", value);
      }
    };

    const reject = (postId) => {
      setInputValues((prevInputValues) => ({
        ...prevInputValues,
        [postId]: "", // Clearing the input value
      }));
      setVisibleInputId(null); // Hide the input field
      console.log("Rejected");
    };

    const TexteLimite = ({ texte, longueurLimite }) => {
      if (!texte) {
        return <span>Tag</span>;
      }

      if (texte.length <= longueurLimite) {
        return <span>{texte}</span>;
      } else {
        const texteLimite = texte.slice(0, longueurLimite) + "...";
        return <span>{texteLimite}</span>;
      }
    };

    const totalComments = post.comments + (post.comments_ad || 0);
    const shouldShowCommentsModeration = totalComments > 0;

    console.log("post ================>", +post.likes + +post.views);

    return (
      <div className="flex flex-row justify-center mb-4 relative">
        {/* <img src={process.env.REACT_APP_API_URL + '/storage/post_library/story/facebook/page_323/949310209861013.jpg'} alt="Description de l'image" />
      
      <img src='https://api.diggow.com/storage/post_library/story/facebook/page_323/398223749235512.jpg' alt="Description de l'image" /> */}

        <div
          key={post.id}
          data-id={"dig-p-" + post.id}
          className="bg-white shadow rounded-lg w-72 mt-8 mb-4"
        >
          <div className="h-32">
            <div className="flex items-center mb-2 px-4 pt-4">
              <div className="flex items-center">
                <img
                  className="w-12 h-12 rounded-full"
                  src={currentPage.page?.picture}
                />
                <div className="ml-2 mt-0.5">
                  <a href={url_post} target="_blank">
                    <span className="block font-medium text-base leading-snug text-black ">
                      {currentPage.page?.name}
                    </span>
                  </a>
                  <span className="block text-sm text-gray-500 dark:text-gray-400 font-light leading-snug">
                    {currentPage.social_type == "tiktok"
                      ? new Date(post.video_date).toDateString()
                      : new Date(post.creation_time).toDateString()}
                  </span>
                </div>
              </div>

              <div className="ml-auto btn_tag">
                <Toast ref={toast} />
                <Button
                  onClick={handleButtonClick}
                  tooltip={post.tag}
                  tooltipOptions={{ position: "top" }}
                >
                  <TexteLimite texte={post.tag} longueurLimite={3} />
                </Button>
                {visibleInputId === post.id && (
                  <div className="absolute right-0 z-10 bg-white px-5 py-5 rounded-[5px] shadow">
                    <h2 className="text-center font-medium mb-2">
                      {t("Post_Library.add_tag")}
                    </h2>
                    <div
                      id={`input_${post.id}`}
                      className="border border-[#d0d5dd] rounded-[5px] mb-2"
                      //className={`border border-[#d0d5dd] rounded-[5px] ${inputError ? 'border-[#e24c4c]' : ''}`}
                    >
                      <InputText
                        type="text"
                        onChange={handleChange}
                        value={inputValues[post.id]}
                        placeholder={post.tag}
                        className="p-2"
                      />
                    </div>
                    <div className="footer_tag ml-auto table">
                      <button
                        className="bg-[#f9f5ff] px-4 py-2 text-[14px] text-[#6941c6] font-semibold rounded-[5px] mr-2"
                        onClick={() => accept(post.id)}
                      >
                        {t("Options.add")}
                      </button>
                      <button
                        className="bg-[#f9f5ff] px-4 py-2 text-[14px] text-[#6941c6] font-semibold rounded-[5px] reject_btn"
                        onClick={() => reject(post.id)}
                      >
                        {t("Options.cancel")}
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <p className="text-asap-blue leading-snug md:leading-normal text-xs px-4 line-clamp-3 ">
              {post.message}
            </p>
          </div>
          {currentPage.social_type === "tiktok" ||
          currentPage.social_type === "youtube" ? (
            <div className="px-4">
              <SkeletonTiktok imageUrl={post.picture} />
            </div>
          ) : (
            <Skeleton
              post={post}
              onLoad={() => changePostAttribute(post.id, "status", "loaded")}
              onError={() => changePostAttribute(post.id, "status", "error")}
            />
          )}

          {show_data && (
            <div>
              <div className="relative">
                {post.score_new && (
                  <div
                    className="flex items-center justify-center text-[12px] text-[#ffffff] rank_score rounded-full font-semibold w-[25px] h-[25px] absolute top-[-25%] right-[5%]"
                    style={{ backgroundColor: score_color[post.score_new] }}
                  >
                    {post.score_new}
                  </div>
                )}

                {currentPage.social_type === "facebook" && (
                  <span className="px-4 block text-[#6941C6] text-[12px] font-bold">
                    {post.reach_paid ? "Sponsorisé" : "Organic"}
                  </span>
                )}
                <div className="grid grid-cols-3  gap-4 mx-auto mb-4 mx-4">
                  <div
                    className={`flex items-center gap-2 ${
                      currentPage.social_type === "facebook"
                        ? "bg-[#f2f4f7]"
                        : ""
                    }`}
                  >
                    <div className="total-fans w-full">
                      <h1 className="text-asap-text text-xs font font-Montserrat font-extrabold">
                        {currentPage.social_type === "tiktok"
                          ? (+post.video_likes || 0) +
                            (+post.video_total_comments || 0) +
                            (+post.video_total_shares || 0) +
                            (+post.video_views || 0) +
                            (+post.video_total_downloads || 0)
                          : currentPage.social_type === "youtube"
                          ? (+post.comments || 0) + (+post.views || 0)
                          : post.clicks +
                            post.comments +
                            (post.comments_ad || 0) +
                            post.shares +
                            (post.likes +
                              (post.likes_ad || 0) +
                              post.wow +
                              post.sad +
                              post.haha +
                              post.angry +
                              post.none +
                              post.love +
                              post.thankful +
                              post.saved +
                              (post.saved_ad || 0))}
                      </h1>
                      <p className="text-asap-text font-Montserrat text-xs">
                        Total Eng
                      </p>
                      <hr className="mt-2" />
                    </div>
                  </div>

                  {currentPage.social_type != "tiktok" ||
                    (currentPage.social_type && (
                      <div className="flex items-center gap-2">
                        <div className="total-fans w-full">
                          <h1 className="text-asap-text text-xs font font-Montserrat font-extrabold">
                            {/* {post.engagement.toFixed(2)} */}
                            {post.reach || post.reach_ad
                              ? (
                                  ((post.clicks +
                                    post.comments +
                                    (post.comments_ad || 0) +
                                    post.shares +
                                    (post.likes +
                                      (post.likes_ad || 0) +
                                      post.wow +
                                      post.sad +
                                      post.haha +
                                      post.angry +
                                      post.none +
                                      post.love +
                                      post.thankful +
                                      post.saved +
                                      (post.saved_ad || 0))) /
                                    (post.reach + (post.reach_ad || 0))) *
                                  100
                                ).toFixed(2) + "%"
                              : 0}
                          </h1>
                          <p className="text-asap-text font-Montserrat text-xs">
                            {t("Post_Library.Taux_Eng")}
                          </p>
                          <hr className="mt-2" />
                        </div>
                      </div>
                    ))}

                  {currentPage.social_type !== "youtube" && (
                    <div className="flex items-center gap-2   mr-2">
                      <div className="total-fans w-full">
                        {currentPage.social_type === "tiktok" && (
                          <>
                            <h1 className="text-asap-text text-xs font font-Montserrat font-extrabold">
                              {+post?.video_total_downloads ?? 0}
                            </h1>
                            <p className="text-asap-text font-Montserrat text-xs">
                              {t("Performance_report.downloads")}
                            </p>
                          </>
                        )}
                        {currentPage.social_type === "facebook" && (
                          <>
                            <h1 className="text-asap-text text-xs font font-Montserrat font-extrabold">
                              {post.reach + (post.reach_ad || 0) ?? 0}
                            </h1>
                            <p className="text-asap-text font-Montserrat text-xs">
                              {t("Performance_report.reach")}
                            </p>
                          </>
                        )}
                        <hr className="mt-2" />
                      </div>
                    </div>
                  )}

                  <div className="total-fans w-full">
                    {currentPage.social_type === "tiktok" ||
                    currentPage.social_type == "youtube" ? (
                      <>
                        <h1 className="text-asap-text text-xs font font-Montserrat font-extrabold">
                          {+post?.video_likes ?? 0}
                        </h1>
                        <p className="text-asap-text font-Montserrat text-xs">
                          {t("Performance_report.likes")}
                        </p>
                      </>
                    ) : (
                      <>
                        <h1 className="text-asap-text text-xs font font-Montserrat font-extrabold">
                          {post.likes +
                            (post.likes_ad || 0) +
                            post.wow +
                            post.sad +
                            post.haha +
                            post.angry +
                            post.none +
                            post.love +
                            post.thankful +
                            post.saved +
                            (post.saved_ad || 0)}
                        </h1>
                        <p className="text-asap-text font-Montserrat text-xs">
                          {t("Performance_report.reactions")}
                        </p>
                      </>
                    )}
                    <hr className="mt-2" />
                  </div>

                  <div className="flex items-center gap-2">
                    <div className="total-fans w-full">
                      <h1 className="text-asap-text text-xs font font-Montserrat font-extrabold">
                        {currentPage.social_type === "tiktok"
                          ? +post.video_total_comments || 0
                          : currentPage.social_type === "youtube"
                          ? +post.comments || 0
                          : post.comments + (post.comments_ad || 0)}
                      </h1>
                      <p className="text-asap-text font-Montserrat text-xs">
                        {t("Performance_report.comments")}
                      </p>
                      <hr className="mt-2" />
                    </div>
                  </div>
                  <div className="flex items-center gap-2">
                    <div className="total-fans w-full">
                      <h1 className="text-asap-text text-xs font font-Montserrat font-extrabold">
                        {currentPage.social_type === "tiktok"
                          ? +post.video_total_shares || 0
                          : currentPage.social_type === "youtube"
                          ? post.video_time
                          : post.shares ?? 0}
                      </h1>
                      <p className="text-asap-text font-Montserrat text-xs">
                        {currentPage.social_type === "youtube"
                          ? t("Performance_report.length")
                          : t("Performance_report.shares")}
                      </p>
                      <hr className="mt-2" />
                    </div>
                  </div>

                  <div className="flex items-center gap-2">
                    <div className="total-fans w-full">
                      <h1 className="text-asap-text text-xs font font-Montserrat font-extrabold">
                        {currentPage.social_type === "tiktok"
                          ? +post.video_views || 0
                          : totalVideoViews
                          ? totalVideoViews
                          : post.views + (post.views_ad || 0) ?? 0}
                      </h1>
                      <p className="text-asap-text font-Montserrat text-xs">
                        {t("Performance_report.views")}
                      </p>
                      <hr className="mt-2" />
                    </div>
                  </div>

                  {currentPage.social_type != "tiktok" ||
                    (currentPage.social_type != "youtube" && (
                      <div className="flex items-center gap-2   mr-2">
                        <div className="total-fans w-full">
                          <h1 className="text-asap-text text-xs font font-Montserrat font-extrabold">
                            {post.clicks ? post.clicks : 0}
                          </h1>
                          <p className="text-asap-text font-Montserrat text-xs">
                            {t("Performance_report.clics")}
                          </p>
                          <hr className="mt-2" />
                        </div>
                      </div>
                    ))}
                </div>
              </div>
              {post.comments + (post.comments_ad || 0) > 0 && (
                <div className="comment_moderation">
                  <Link
                    to={`/reporting/${currentPage.page_id}/post/${post.id}`}
                    state={{ getKeys, post }}
                  >
                    {t("Post_Library.Comments_moderation")}
                  </Link>
                </div>
              )}
            </div>
          )}

          {post.status_type == "STORY" && post.social_type == "instagram" && (
            <div>
              <div className="relative">
                {post.score_new && (
                  <div
                    className="flex items-center justify-center text-[12px] text-[#ffffff] rank_score rounded-full font-semibold w-[25px] h-[25px] absolute top-[-25%] right-[5%]"
                    style={{ backgroundColor: score_color[post.score_new] }}
                  >
                    {post.score_new}
                  </div>
                )}

                {currentPage.social_type === "facebook" && (
                  <span className="px-4 block text-[#6941C6] text-[12px] font-bold">
                    {post.reach_paid ? "Sponsorisé" : "Organic"}
                  </span>
                )}
                <div className="grid grid-cols-3  gap-4 mx-auto mb-4 mx-4">
                  <div
                    className={`flex items-center gap-2 ${
                      currentPage.social_type === "facebook"
                        ? "bg-[#f2f4f7]"
                        : ""
                    }`}
                  >
                    <div className="total-fans w-full">
                      <h1 className="text-asap-text text-xs font font-Montserrat font-extrabold">
                        {total_interactions}
                      </h1>
                      <p className="text-asap-text font-Montserrat text-xs">
                        {t("Post_Library.Interactions")}
                      </p>
                      <hr className="mt-2" />
                    </div>
                  </div>

                  <div className="flex items-center gap-2">
                    <div className="total-fans w-full">
                      <h1 className="text-asap-text text-xs font font-Montserrat font-extrabold">
                        {/* {post.engagement.toFixed(2)} */}
                        {total_views}
                      </h1>
                      <p className="text-asap-text font-Montserrat text-xs">
                        {t("Performance_report.views")}
                      </p>
                      <hr className="mt-2" />
                    </div>
                  </div>

                  <div className="flex items-center gap-2   mr-2">
                    <div className="total-fans w-full">
                      <h1 className="text-asap-text text-xs font font-Montserrat font-extrabold">
                        {post_reach}
                      </h1>
                      <p className="text-asap-text font-Montserrat text-xs">
                        {t("Performance_report.reach")}
                      </p>
                      <hr className="mt-2" />
                    </div>
                  </div>

                  <div className="total-fans w-full">
                    <h1 className="text-asap-text text-xs font font-Montserrat font-extrabold">
                      {post_partage}
                    </h1>
                    <p className="text-asap-text font-Montserrat text-xs">
                      {t("Performance_report.shares")}
                    </p>
                    <hr className="mt-2" />
                  </div>

                  <div className="flex items-center gap-2">
                    <div className="total-fans w-full">
                      <h1 className="text-asap-text text-xs font font-Montserrat font-extrabold">
                        {tap_exit}
                      </h1>
                      <p className="text-asap-text font-Montserrat text-xs">
                        {t("Performance_report.exit")}
                      </p>
                      <hr className="mt-2" />
                    </div>
                  </div>
                  <div className="flex items-center gap-2">
                    <div className="total-fans w-full">
                      <h1 className="text-asap-text text-xs font font-Montserrat font-extrabold">
                        {replies}
                      </h1>
                      <p className="text-asap-text font-Montserrat text-xs">
                        exit{t("Performance_report.response")}
                      </p>
                      <hr className="mt-2" />
                    </div>
                  </div>

                  <div className="flex items-center gap-2">
                    <div className="total-fans w-full">
                      <h1 className="text-asap-text text-xs font font-Montserrat font-extrabold">
                        {tap_back}
                      </h1>
                      <p className="text-asap-text font-Montserrat text-xs">
                        Prev
                      </p>
                      <hr className="mt-2" />
                    </div>
                  </div>

                  <div className="flex items-center gap-2   mr-2">
                    <div className="total-fans w-full">
                      <h1 className="text-asap-text text-xs font font-Montserrat font-extrabold">
                        {tap_forward}
                      </h1>
                      <p className="text-asap-text font-Montserrat text-xs">
                        Next
                      </p>
                      <hr className="mt-2" />
                    </div>
                  </div>

                  <div className="flex items-center gap-2   mr-2">
                    <div className="total-fans w-full">
                      <h1 className="text-asap-text text-xs font font-Montserrat font-extrabold">
                        {swipe_forward}
                      </h1>
                      <p className="text-asap-text font-Montserrat text-xs">
                        Swipe
                      </p>
                      <hr className="mt-2" />
                    </div>
                  </div>
                </div>
              </div>

              {post.comments + (post.comments_ad || 0) > 0 && (
                <div className="comment_moderation">
                  <Link
                    to={`/reporting/${currentPage.page_id}/post/${post.id}`}
                    state={{ getKeys, post }}
                  >
                    {t("Post_Library.Comments_moderation")}
                  </Link>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    );
  };
  if (status == "loading") return <Loader />;

  const valuesArray = [];
  posts.forEach((post) => {
    const reaction_post =
      post.likes +
      (post.likes_ad || 0) +
      post.wow +
      post.sad +
      post.haha +
      post.angry +
      post.none +
      post.love +
      post.thankful +
      post.saved +
      (post.saved_ad || 0);
    const comment_post = post.comments + (post.comments_ad || 0);

    //-- engagement
    let engagement_post = 0;
    if (currentPage.social_type === "instagram") {
      engagement_post =
        post.reach || post.reach_ad
          ? (
              ((post.clicks +
                post.comments +
                (post.comments_ad || 0) +
                post.shares +
                (post.likes +
                  (post.likes_ad || 0) +
                  post.wow +
                  post.sad +
                  post.haha +
                  post.angry +
                  post.none +
                  post.love +
                  post.thankful +
                  post.saved +
                  (post.saved_ad || 0))) /
                (post.reach + (post.reach_ad || 0))) *
              100
            ).toFixed(2) + "%"
          : 0;
    } else if (currentPage.social_type === "youtube") {
      engagement_post =
        (+post.comments || 0) + (+post.views || 0) + (+post.shares || 0);
    } else {
      engagement_post =
        post.reach_paid || post.reach_organic || post.reach_ad
          ? (
              ((post.clicks +
                post.comments +
                (post.comments_ad || 0) +
                post.shares +
                (post.likes +
                  (post.likes_ad || 0) +
                  post.wow +
                  post.sad +
                  post.haha +
                  post.angry +
                  post.none +
                  post.love +
                  post.thankful +
                  post.saved +
                  (post.saved_ad || 0))) /
                (post.reach_paid + post.reach_organic + (post.reach_ad || 0))) *
              100
            ).toFixed(2) + "%"
          : 0;
    }

    //-- views
    let views_post_data = 0;
    if (currentPage.social_type === "instagram") {
      views_post_data = post?.views ? post.views : 0;
    } else {
      const parsedData = post && post.data ? post.data : null;
      const json =
        typeof parsedData === "object" ? parsedData : JSON.parse(parsedData);
      views_post_data = json?.data?.total_video_views ?? 0;
    }

    const views_post = views_post_data + (post.views_ad || 0);

    //-- reach
    let reach_post = 0;
    if (currentPage.social_type === "instagram") {
      reach_post =
        post.reach || post.reach_ad ? post.reach + (post.reach_ad || 0) : 0;
    } else {
      reach_post = post.reach ?? 0;
    }

    let data_values = {};

    if (currentPage.social_type === "facebook")
      data_values = {
        name: currentPage?.page?.name,
        social_type: currentPage?.page?.social_type,
        creation_time: post?.creation_time ?? "",
        message: post?.message ?? "",
        picture: post?.picture ?? "",
        reaction: reaction_post ?? 0,
        comment: comment_post ?? 0,
        shares: post?.shares ?? 0,
        engagement: engagement_post ?? 0,
        views: views_post ?? 0,
        reach: reach_post ?? 0,
        click: post.clicks ?? 0,
      };
    else if (currentPage?.social_type === "youtube") {
      data_values = {
        name: currentPage?.page?.name,
        social_type: currentPage?.page?.social_type,
        creation_time: post?.creation_time ?? "",
        title: post?.message ?? "",
        description: post?.description ?? "",
        picture: post?.picture ?? "",
        likes: post?.video_likes ?? 0,
        comment: post?.comments ?? 0,
        length: post.video_time ?? "00:00:00",
        engagement: engagement_post ?? 0,
        views: post?.views ?? 0,
      };
    }

    const keys = Object.keys(data_values);
    const values = keys.map((key) => data_values[key]);
    valuesArray.push(values);
  });

  const item_comment = [
    "name",
    "social_type",
    "creation_time",
    "title",
    "description",
    "picture",
    "likes",
    "comment",
    "length",
    "engagement",
    "views",
  ];
  const exportToExcel = () => {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.aoa_to_sheet([item_comment, ...valuesArray]);
    XLSX.utils.book_append_sheet(wb, ws, "Données statistiques des posts");
    XLSX.writeFile(wb, "donnees_posts.xlsx");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setInputSearch("");
    setSearchValue("");
    const formatTypeName = formatType && formatType.name;

    const formData = {
      textTag,
      contentType,
      formatType: formatTypeName ?? "",
      performance,
      promotedPost,
    };

    setFormSearch(formData);
  };

  const clearForm = () => {
    setTextTag("");
    setContentType([]);
    setFormatType(null);
    setPerformance([]);
    setPromotedPost([]);
    const clearedForm = Object.fromEntries(
      Object.entries(formSearch).map(([key, value]) => [key, null])
    );

    // Update the formSearch state with the clearedForm
    setFormSearch(clearedForm);
  };

  const formatTypeItems = [
    { name: "Image", code: "Image" },
    { name: "Video", code: "Video" },
  ];

  const engagement = searchData.engagement
    ? searchData.engagement.toLocaleString()
    : 0;
  const taux_engagement = searchData.taux_engagement
    ? searchData.taux_engagement.toLocaleString()
    : 0;
  const portee = searchData.portee ? searchData.portee.toLocaleString() : 0;

  return (
    <div className="">
      <div className="justify-between flex mt-4 mx-6 items-center text-sm 2xl:text-base">
        {/* calendar */}
        <div className="flex items-center gap-6">
          <div className="flex bg-white border-gray-300  border  items-center rounded font-CityOfEmber   calendar-navbar w-72  ">
            <label
              htmlFor="date_range"
              className="cursor-pointer pl-6 dark:text-asap-white text-asap-blue"
            >
              <CalendarIcon />
            </label>
            <Calendar
              inputId="date_range"
              dateFormat="yy/mm/dd"
              panelClassName="mt-6 bg-asap-gray-900 p-2 font-CityOfEmber text-gray-900 font-bold  "
              className="font-CityOfEmber text-gray-900 font-bold "
              inputClassName=" ml-2 cursor-pointer   outline-none  text-asap-blue text-sm text-gray-900 font-semibold "
              id="range"
              value={dates2}
              onChange={(e) => {
                setDates2(e.value);
                if (e.value[0] != null && e.value[1] != null)
                  setState({ ...state, reportingPeriod: e.value });
              }}
              selectionMode="range"
              readOnlyInput
              numberOfMonths={1}
            />
            <label
              htmlFor="date_range"
              className="cursor-pointer pl-2 cursor-pointer dark:text-asap-white text-asap-blue"
            >
              <CalendarArrow />
            </label>
          </div>
          <div className="flex gap-2">
            <span>{t("Performance_report.period")}</span>
            {dates2[0] && (
              <div className="flex gap-2">
                <span>{dates2[0]?.toDateString()}</span> -
                <span>{dates2[1]?.toDateString()}</span>
              </div>
            )}
          </div>
        </div>

        <div className="mr-5 ml-auto">
          <span className="p-input-icon-left w-full border border-[#d0d5dd] rounded-[6px]">
            <i className="pi pi-search" />
            <InputText
              value={inputSearch}
              placeholder={t("Options.search")}
              onChange={handleSeachChange}
              onKeyPress={handleKeyPress}
              className="text-[#667085] text-[16px] placeholder:text-[#667085] w-full py-2"
            />
          </span>
        </div>

        <div className="flex items-center border bg-[#596ae9] rounded-[6px] border-gray-300 h-10 px-3">
          <button
            type="button"
            id="search_icon"
            onClick={(e) => op.current.toggle(e)}
            className=" text-white text-[14px] pr-2"
          >
            {t("Options.advanced_search")}
          </button>

          <label
            htmlFor="search_icon"
            className="cursor-pointer text-white text-[14px] flex items-center"
          >
            {Object.values(formSearch).some(
              (value) => value != null && value != ""
            ) ? (
              <i className="pi pi-times" onClick={() => clearForm()} />
            ) : (
              <i className="pi pi-sort-down" />
            )}
          </label>
        </div>
        <OverlayPanel ref={op}>
          <div className="search_advancer">
            <form onSubmit={handleSubmit}>
              <div className="mb-5">
                <label className="text-[14px] table pb-2 font-bold">
                  Content tag
                </label>
                <div className="p-input-icon-left w-full border border-[#d0d5dd] rounded-[6px]">
                  <i className="pi pi-search" />
                  <InputText
                    type="text"
                    value={textTag}
                    placeholder={t("Options.search")}
                    onChange={(e) => setTextTag(e.target.value)}
                    className="text-[#667085] text-[16px] placeholder:text-[#667085] w-full py-2"
                  />
                </div>
              </div>

              <div className="mb-5">
                <label className="text-[14px] table pb-2 font-bold">
                  {t("advanced_search.Content_type")}
                </label>
                <div className="flex">
                  {[
                    { type: "Posts", iconClass: "pi pi-list" },
                    { type: "Stories", iconClass: "pi pi-history" },
                    { type: "Reels", iconClass: "pi pi-camera" },
                  ].map((item, index) => (
                    <label
                      key={item.type}
                      className={`flex items-center text-[#344054] text-[14px] font-semibold px-2 py-1 border bg-white rounded border-gray-300 cursor-pointer ${
                        contentType.includes(item.type)
                          ? "text-[#6941c6] bg-[#f9f5ff]"
                          : ""
                      } ${index % 2 !== 0 ? "mx-2" : ""}`}
                    >
                      <input
                        hidden
                        type="checkbox"
                        value={item.type}
                        checked={contentType.includes(item.type)}
                        onChange={() => {
                          const updatedContentTypes = contentType.includes(
                            item.type
                          )
                            ? contentType.filter(
                                (currentType) => currentType !== item.type
                              )
                            : [...contentType, item.type];
                          setContentType(updatedContentTypes);
                        }}
                      />
                      <i className={`text-[13px] mr-2 ${item.iconClass}`} />
                      {item.type}
                    </label>
                  ))}
                </div>
              </div>

              <div className="mb-5">
                <label className="text-[14px] table pb-2 font-bold">
                  {t("advanced_search.media_type")}
                </label>
                <div className="border border-[#d0d5dd] rounded-[6px] bg-white">
                  <Dropdown
                    value={formatType}
                    options={formatTypeItems}
                    className="dropdown_media w-full"
                    onChange={(e) => setFormatType(e.value)}
                    optionLabel="name"
                    placeholder="Select media type"
                    showClear={
                      formatType !== null &&
                      formatType !== undefined &&
                      formatType !== ""
                    }
                  />
                </div>
              </div>

              <div className="mb-5">
                <label className="text-[14px] table pb-2 font-bold">
                  {t("advanced_search.performance_prediction")}
                </label>
                <div className="flex">
                  {["A+", "A", "B", "C", "D"].map((type, index) => (
                    <label
                      key={type}
                      style={{
                        backgroundColor: performance.includes(type)
                          ? score_color[type]
                          : "",
                      }}
                      className={`table text-[#344054] text-[14px] font-semibold px-2 py-1 border bg-white rounded border-gray-300 cursor-pointer ${
                        performance.includes(type) ? `text-[#ffffff]` : ""
                      } ${index % 2 != 0 ? "mx-2" : ""}`}
                    >
                      <input
                        hidden
                        type="checkbox"
                        value={type}
                        checked={performance.includes(type)}
                        onChange={() => {
                          const updatedPerformance = performance.includes(type)
                            ? performance.filter((item) => item !== type)
                            : [...performance, type];
                          setPerformance(updatedPerformance);
                        }}
                      />
                      {type}
                    </label>
                  ))}
                </div>
              </div>

              <div className="mb-5">
                <label className="text-[14px] table pb-2 font-bold">
                  {t("advanced_search.Promoted_Post")}
                </label>
                <div className="flex">
                  {[t("Post_Library.organic"), t("Post_Library.promoted")].map(
                    (type, index) => (
                      <label
                        key={type}
                        className={`table text-[#344054] text-[14px] font-semibold px-2 py-1 border bg-white rounded border-gray-300 cursor-pointer ${
                          promotedPost.includes(type)
                            ? "text-[#6941c6] bg-[#f9f5ff]"
                            : ""
                        } ${index % 2 != 0 ? "mx-2" : ""}`}
                      >
                        <input
                          hidden
                          type="checkbox"
                          value={type}
                          checked={promotedPost.includes(type)}
                          onChange={() => {
                            const updatedPromotedPost = promotedPost.includes(
                              type
                            )
                              ? promotedPost.filter((item) => item !== type)
                              : [...promotedPost, type];
                            setPromotedPost(updatedPromotedPost);
                          }}
                        />
                        {type}
                      </label>
                    )
                  )}
                </div>
              </div>

              <button
                type="submit"
                className="bg-[#596ae9] text-white text-[16px] px-5 py-2 table font-bold rounded-[6px] ml-auto"
              >
                {t("Options.submit")}
              </button>
            </form>
          </div>
        </OverlayPanel>
      </div>

      {/* selectbar */}
      <div className="flex items-center justify-between  mt-8">
        <div className="flex items-center">
          <h1 className="text-2xl float-left font-bold ml-4 ">
            {t("Post_Library.post_library")}
          </h1>
          <span className="ml-5 bg-[#EAECF0] text-[12px] text-[#344054] font-semibold p-[5px] px-2 py-1 rounded-[16px]">
            {numberOfPosts}
          </span>
        </div>

        <div className=" float-right   mr-8 flex gap-2  ">
          <div className="flex items-center border bg-white rounded border-gray-300 h-10">
            <button
              className="table ml-auto text-[#344054] text-[14px] font-semibold px-3 py-3"
              onClick={exportToExcel}
            >
              {t("Options.export")}
            </button>
          </div>
          {currentPage.social_type != "tiktok" && (
            <div className="flex items-center border bg-white rounded border-gray-300 h-10">
              <img className="w-6 h-6 ml-4" src={dropdownIcon} alt="" />
              <div>
                <Dropdown
                  className=" float-right text-gray-800 "
                  value={filterStatusType}
                  options={filterTypeOptions}
                  onChange={onFilterTypeChange}
                  optionLabel="name"
                  placeholder="Type de posts"
                />
              </div>
            </div>
          )}
          {currentPage.social_type !== "youtube" && (
            <div className="flex items-center border bg-white rounded border-gray-300 h-10">
              <img className="w-6 h-6 ml-4" src={dropdownIcon} alt="" />
              <div>
                <Dropdown
                  className=" float-right text-gray-800 "
                  value={filterCriteria}
                  options={filterCriteriasOptions}
                  onChange={onFilterChange}
                  optionLabel="name"
                  placeholder="Format des posts"
                />
              </div>
            </div>
          )}
          <div className="flex items-center border bg-white rounded border-gray-300 h-10">
            <img className="w-6 h-6 ml-4" src={dropdownIcon} alt="" />
            <div>
              <Dropdown
                className="float-right text-gray-800 "
                value={sortCriteria}
                options={sortCriteriasOptions}
                onChange={onSortChange}
                optionLabel="name"
                placeholder="Critère de tri"
              />
            </div>
          </div>
        </div>
      </div>

      {/* Stat */}
      {searchData.nbr_post > 0 && (
        <div className=" mt-8 flex gap-6 justify-center text-gray-700 text-sm 2xl:text-base">
          {/* cards */}
          {searchData.nbr_post > 0 && (
            <div className="bg-white px-4 py-5 rounded-[8px] w-full border border-[#eaecf0]">
              <div className="flex items-center">
                <div className="icon_cards p-3 shadow-[0_1px_2px_0_rgba(16,24,40,0.05)] border border-[#eaecf0] rounded-[8px]">
                  <FansIcon />
                </div>
                <h2 className="text-[#101828] text-[16px] font-medium ml-4">
                  Total Posts
                </h2>
              </div>
              <h3 className="font-semibold text-[#101828] text-[30px] mt-4">
                {searchData.nbr_post.toLocaleString()}
              </h3>
            </div>
          )}
          {/* cards */}

          {/* cards */}
          {searchData.interaction > 0 && (
            <div className="bg-white px-4 py-5 rounded-[8px] w-full border border-[#eaecf0]">
              <div className="flex items-center">
                <div className="icon_cards p-3 shadow-[0_1px_2px_0_rgba(16,24,40,0.05)] border border-[#eaecf0] rounded-[8px]">
                  <FansIcon />
                </div>
                <h2 className="text-[#101828] text-[16px] font-medium ml-4">
                  Total Interactions
                </h2>
              </div>
              <h3 className="font-semibold text-[#101828] text-[30px] mt-4">
                {searchData.interaction.toLocaleString()}
              </h3>
            </div>
          )}
          {/* cards */}

          {/* cards */}
          {searchData.engagement > 0 && (
            <div className="bg-white px-4 py-5 rounded-[8px] w-full border border-[#eaecf0]">
              <div className="flex items-center">
                <div className="icon_cards p-3 shadow-[0_1px_2px_0_rgba(16,24,40,0.05)] border border-[#eaecf0] rounded-[8px]">
                  <FansIcon />
                </div>
                <h2 className="text-[#101828] text-[16px] font-medium ml-4">
                  Total Engagement
                </h2>
              </div>
              <h3 className="font-semibold text-[#101828] text-[30px] mt-4">
                {engagement.toLocaleString()}
              </h3>
            </div>
          )}
          {/* cards */}

          {/* cards */}
          {searchData.taux_engagement > 0 && (
            <div className="bg-white px-4 py-5 rounded-[8px] w-full border border-[#eaecf0]">
              <div className="flex items-center">
                <div className="icon_cards p-3 shadow-[0_1px_2px_0_rgba(16,24,40,0.05)] border border-[#eaecf0] rounded-[8px]">
                  <FansIcon />
                </div>
                <h2 className="text-[#101828] text-[16px] font-medium ml-4">
                  Taux D'engagement
                </h2>
              </div>
              <h3 className="font-semibold text-[#101828] text-[30px] mt-4">
                {taux_engagement.toLocaleString()}
              </h3>
            </div>
          )}
          {/* cards */}

          {/* cards */}
          {searchData.portee > 0 && (
            <div className="bg-white px-4 py-5 rounded-[8px] w-full border border-[#eaecf0]">
              <div className="flex items-center">
                <div className="icon_cards p-3 shadow-[0_1px_2px_0_rgba(16,24,40,0.05)] border border-[#eaecf0] rounded-[8px]">
                  <FansIcon />
                </div>
                <h2 className="text-[#101828] text-[16px] font-medium ml-4">
                  {t("Performance_report.reach")}
                </h2>
              </div>
              <h3 className="font-semibold text-[#101828] text-[30px] mt-4">
                {portee}
              </h3>
            </div>
          )}
          {/* cards */}

          {/* cards */}
          {searchData.video_views > 0 && (
            <div className="bg-white px-4 py-5 rounded-[8px] w-full border border-[#eaecf0]">
              <div className="flex items-center">
                <div className="icon_cards p-3 shadow-[0_1px_2px_0_rgba(16,24,40,0.05)] border border-[#eaecf0] rounded-[8px]">
                  <FansIcon />
                </div>
                <h2 className="text-[#101828] text-[16px] font-medium ml-4">
                  {t("Performance_report.total_views")}
                </h2>
              </div>
              <h3 className="font-semibold text-[#101828] text-[30px] mt-4">
                {searchData.video_views}
              </h3>
            </div>
          )}
          {/* cards */}
        </div>
      )}

      <VirtualScroller
        className="w-full  mx-auto mt-4 mb-16"
        style={{ height: 1900 }}
        items={postsToRender}
        itemSize={100}
        itemTemplate={tpl}
      />
    </div>
  );
};

export default PostLibrary;
